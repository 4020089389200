import { useState } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';
import BarChartVertical from "../charts/tab2/BarChartVertical";
//import BarChartVerticalSmall from "../charts/tab2/BarChartVerticalSmall";
import TabularChart from "../charts/TabularChart";
import { Spinner } from "../spinner/Spinner"
import { Modal, Button } from 'react-bootstrap';
import { generate_New_Filter_ArrayObj } from "../../util";

// eslint-disable-next-line
let diagnosisOptions = [
    {
        index: 1,
        value: "F009",
        text: "No filter",
    },
    {
        index: 2,
        value: "F006",
        text: "F006 notFound",
    },
    {
        index: 3,
        value: "F005",
        text: "F005 notFound",
    },
    {
        index: 4,
        value: "F001",
        text: "F001 notFound",
    },
    {
        index: 5,
        value: "F003",
        text: "F003 notFound",
    },
    {
        index: 6,
        value: "F002",
        text: "F002 notFound",
    },
    {
        index: 7,
        value: "F004",
        text: "F004 notFound",
    },
    {
        index: 8,
        value: "F007",
        text: "F007 notFound",
    },
    {
        index: 9,
        value: "F008",
        text: "F008 notFound",
    },
];

const filterOption1 = [
    {
        index: 1,
        value: "T002",
        text: "Patients using their own eggs",
    },
    {
        index: 2,
        value: "T003",
        text: "Patients with no prior ART using their own eggs",
    },
];

function Tab2({ user, actions, isLoading, tables }) {
    //console.log(tables);
    //setting data for drop down, pagination and question description
    var filterOption2_1 = generate_New_Filter_ArrayObj(tables.Questions, 0, 24),
        filterOption2_2 = generate_New_Filter_ArrayObj(tables.Questions, 25, 29)

    const [filterVal1, setFilterVal1] = useState(0);
    const [filterVal2, setFilterVal2] = useState(0);
    const [diagnosisVal, setDiagnosisVal] = useState(0)
    const [showTabularChart, setShowTabularChart] = useState(false);

    //setting show description text container
    const [truncateText1, setTruncateText1] = useState(true);
    const [truncateText2, setTruncateText2] = useState(true);

    //setting modal
    const [visibleFilterMeta, setVisibleFilterMeta] = useState(false);
    const btnCloseFilterMeta = () => setVisibleFilterMeta(false);
    const btnShowFilterMeta = () => setVisibleFilterMeta(true);

    if (isLoading) {
        return (
            <div>
                <>
                    <Spinner
                        message="Loading Data..."
                        backGround={true}
                        center={true}
                    />
                </>
            </div>
        )
    }

    const changeFilter = (index1, index2) => {
        //console.log("index1", index1);
        //console.log("index2", index2);
        if (index1 !== filterVal1) {
            //console.log("type changed");
            index2 = 0;
        }
        setFilterVal1(index1);
        setFilterVal2(index2);
    };

    const changeDiagnosis = (obj) => {
        //console.log("diagnosisVal ", obj.index);
        setDiagnosisVal(obj.index);
    };

    const handleKeyPressDiagnosis = (e, obj) => {
        if (e.code === "Enter" || e.which === 13) {
            setDiagnosisVal(obj.index);
        }
    }

    //method to determine what type of eggs to set dropdown set of values
    const getSelectBoxText = () => {
        var useFilterOption = filterOption2_1;
        if (filterVal1 === "1") {
            useFilterOption = filterOption2_2;
        }

        return useFilterOption[filterVal2].Question;
    }

    const typeId = `${filterOption1[filterVal1].value}`;
    const filterId = `${diagnosisOptions[diagnosisVal].value}`;
    // above may need to use useFilterOption

    const diagnosisFilters = tables.Filters
    if (typeof (diagnosisFilters) == 'undefined' || diagnosisFilters == null) return <p>Error: cannot find diagnosis Filters.</p>;
    // console.log(diagnosisFilters)
    for (let i = 0; i < diagnosisFilters.length; i++) {
        let diagFilter = diagnosisFilters[i]
        let diagOption = diagnosisOptions.find(diagOption => diagOption.value === diagFilter.FilterId)
        if (diagOption) diagOption.text = diagnosisFilters[i].FilterName
        else console.log('Warning: unexpected diagnosis Filters found ' + diagFilter.FilterId)
        // console.log(diagFilter)
    }
    // console.log(diagnosisOptions)

    const questions = tables.Questions
    if (typeof (questions) == 'undefined' || questions == null) return <p>Error: cannot find Questions.</p>;
    // console.log(questions)
    for (let i = 0; i < questions.length; i++) {
        let question = questions[i]
        let questOption1 = filterOption2_1.find(questOption => questOption.value === question.QuestionsIdId)
        if (questOption1) questOption1.text = questions[i].Question
        else {
            let questOption2 = filterOption2_2.find(questOption => questOption.value === question.QuestionsIdId)
            if (questOption2) questOption2.text = questions[i].Question
            else console.log('Warning: unexpected question found ' + question.QuestionsIdId)
        }
        // console.log(question)
    }

    var useFilterOption = filterOption2_1;
    if (filterVal1 === "1") {
        useFilterOption = filterOption2_2;
    }

    //Select a success factor drop down <option>
    let option2List = useFilterOption.map((filter, index) => {
        return (
            <option key={filter.value} value={index}>
                {filter.Question}
            </option>
        )
    });

    const questionId = `${useFilterOption[filterVal2].value}`;
    // console.log("loaded for "+ typeId + "_" + questionId + " filterId: " + filterId)

    const questionData = tables.Data.find(question => question.QuestionId === questionId && question.FilterId === filterId)
    if (typeof (questionData) == 'undefined' || questionData == null) return <p>Error: cannot find data for question and diagnosis.</p>;

    const questionKey = questionId + "_" + filterId
    const tableHeaderText = questionData.TableHeader
    var tableFooterText = questionData.TableFooter
    for (var i = tableFooterText.length - 1, c = 0; i >= 0; i--) {
        if (tableFooterText.charAt(i) >= '0' && tableFooterText.charAt(i) <= '9') {
            c++
        } else {
            c = 0
        }
        if (c === 4) {
            tableFooterText = tableFooterText.substring(0, i + 1) + ',' + tableFooterText.substring(i + 1)
            break;
        }
    }

    let Report;
    const title = 'Age of Patient'
    const showChart = (questionData.DataRows[5] === 'True')

    let yAxisFormat = 'percent'
    if (questionId === "Q023" || questionId === "Q028" || questionId === "Q029")
        yAxisFormat = 'number3'
    else if (questionId === "Q024")
        yAxisFormat = 'number15'

    switch (typeId + "_" + questionId) {
        case "T002_Q011":
        case "T002_Q012":
        case "T002_Q013":
        case "T002_Q014":
        case "T002_Q015":
        case "T002_Q016":
        case "T002_Q017":
        case "T002_Q018":
        case "T002_Q019":
        case "T002_Q020":
        case "T002_Q021":
        case "T002_Q022":
        case "T002_Q023":
        case "T002_Q024":
        case "T003_Q025":
        case "T003_Q026":
        case "T003_Q027":
        case "T003_Q028":
        case "T003_Q029":
            //alert('vertical ' + questionId)
            if (!showTabularChart && showChart) {
                Report =
                    <BarChartVertical
                        data={questionData}
                        key={questionKey}
                        title='Age of Patient'
                        yAxisFormat={yAxisFormat}
                    />
            } else {
                Report =
                    <TabularChart
                        data={questionData}
                        key={questionKey}
                        showFractional={!showChart}
                    />
            }
            break;
        default:
            break;
    }

    return (
        <div className="tab_in pt-2">
            <Modal show={visibleFilterMeta} onHide={btnCloseFilterMeta} className="metaDataTableModal" aria-labelledby="metaDataTableModal">
                <Modal.Header>
                    <Modal.Title id="metaDataTableModal">Diagnosis Description</Modal.Title>
                    <Button onClick={btnCloseFilterMeta} className="bg-transparent border-0 text-dark">
                        X
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <table>
                        <thead>
                            <tr>
                                <th className="h5 py-3">Diagnosis</th>
                                <th className="h5 py-3">Description</th>
                            </tr>
                        </thead>
                        <tbody className="table table-bordered bl-0" >
                            {tables.Filters && tables.Filters.map((row, index) => (
                                <tr key={index} >
                                    <td className="small">{row.FilterName}</td>
                                    <td className="small">{row.FilterDescription}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table></Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={btnCloseFilterMeta}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <div id="tab2Title1" className={truncateText1 ? "truncate-overflow" : ""}>
                {tables.TopicDescription[0].TopicDescription}
            </div>
            <div className="d-print-none text-right mt-1">
                <a
                    href="#/"
                    onClick={() => setTruncateText1(!truncateText1)}
                >
                    {truncateText1 ? "Show full description" : "Hide full description"}
                    <i class={`align-middle ml-1 fa fa-2x ${truncateText1 ? "fa-angle-down" : "fa-angle-up"}`}></i>
                </a>
            </div>
            <div className="bordered_grey_bot">
                <div className="top">
                    <div className="columns row">
                        <div className="d-print-none column row col-12 is-one-quarter">
                            <div
                                className="pt-2"
                                style={{ width: "170px", marginLeft: "15px" }}
                            >
                                <b id="lblSelType">Patient type</b>
                            </div>
                            <select
                                id="selType"
                                className="form-control col-5"
                                value={filterVal1}
                                onChange={(e) => {
                                    changeFilter(e.target.value, filterVal2);
                                }}
                                aria-labelledby="lblSelType"
                                style={{ position: "relative", left: "2em" }}
                            >
                                {filterOption1.map((filter, index) => (
                                    <option key={filter.index} value={index}>
                                        {filter.text}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="lblTab3QuestionWrap column row col-12 mt-3">
                            <div id="lblTab2Question"
                                className="pt-2 d-print-none"
                                style={{ width: "170px", marginLeft: "15px" }}
                            ><b>Select a success factor</b></div>
                            <select
                                id="tab2Question"
                                className="form-control"
                                value={filterVal2}
                                key={filterVal1}
                                onChange={(e) => {
                                    changeFilter(
                                        filterVal1,
                                        e.target.value
                                    );
                                }}
                                aria-labelledby="lblTab2Question"
                                style={{ width: "790px", position: "relative", left: "2em" }}
                            >
                                {option2List}
                            </select>
                        </div>
                        <div className="d-print-none col-12 text-right mt-2">
                            <span className="col font-italic ">
                                viewing {
                                    useFilterOption[filterVal2].index
                                } of {useFilterOption.length}
                            </span>
                            <button
                                className="btn btn-secondary is-info is-small mr-1"
                                disabled={
                                    useFilterOption[filterVal2].index ===
                                    1
                                }
                                onClick={() => {
                                    changeFilter(
                                        filterVal1,
                                        +filterVal2 - 1
                                    );
                                }}
                            >
                                Previous
                            </button>
                            <button
                                className="btn btn-secondary is-info is-small"
                                disabled={
                                    useFilterOption[filterVal2].index ===
                                    useFilterOption.length
                                }
                                onClick={() => {
                                    changeFilter(
                                        filterVal1,
                                        +filterVal2 + 1
                                    );
                                }}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                    <p className={`mt-3 mb-2  ${truncateText2 ? "truncate-overflow" : ""} `} id="tab2Title2">
                        {useFilterOption[filterVal2].header}
                    </p>
                    {useFilterOption[filterVal2].header.length > 260 &&
                        <div className="d-print-none text-right mt-1">
                            <a
                                href="#/"
                                onClick={() => setTruncateText2(!truncateText2)}
                            >
                                {truncateText2 ? "Show full description" : "Hide full description"}
                                <i className={`align-middle ml-1 fa fa-2x ${truncateText2 ? "fa-angle-down" : "fa-angle-up"}`}></i>
                            </a>
                        </div>
                    }
                </div>
                <div className="bot">
                    <div className="columns row">
                        <div className="column col-3 d-print-none">
                            {/*<p className="is-hidden-mobile mb-3">&nbsp;</p>*/}
                            <div className="filter">
                                <div className="title">
                                    Diagnosis
                                    <button
                                        className="button js-modal-trigger question_btn"
                                        data-target="modal_help_1"
                                        aria-haspopup="true"
                                        onClick={btnShowFilterMeta}
                                    >
                                        ?
                                    </button>
                                </div>
                                {diagnosisOptions.map((diagnosis, index) => {
                                    if (diagnosisVal === index)
                                        return <div tabIndex="0" className="item active font-weight-bold"
                                            onKeyPress={(e) => { handleKeyPressDiagnosis(e, { index }) }}
                                            onClick={() => {
                                                changeDiagnosis({ index });
                                            }}>{diagnosis.text}</div>
                                    return <div tabIndex="0" className="item"
                                        onKeyPress={(e) => { handleKeyPressDiagnosis(e, { index }) }}
                                        onClick={() => {
                                            changeDiagnosis({ index });
                                        }}>{diagnosis.text}</div>
                                })}
                            </div>
                        </div>
                        <div className="column col-9 filter_result_wrap">
                            <div id="pdfDiv">
                                <div className="filter_result">
                                    <div className="title">
                                        {filterOption1[filterVal1].text}
                                    </div>
                                    <div className="filter_result_bot">
                                        <p>{getSelectBoxText()}</p>
                                        <div style={{ float: "right" }} className="chartButton">
                                            {showChart && <>
                                                <button
                                                    style={{ padding: "0px 0px", borderWidth: "0px" }}
                                                    onClick={() => { setShowTabularChart(false); }}
                                                >
                                                    {!showTabularChart && showChart &&
                                                        <img src="images/chart-toggle-selected.png" alt="show chart" />
                                                    }
                                                    {showTabularChart && showChart &&
                                                        <img src="images/chart-toggle-unselected.png" alt="show chart" />
                                                    }
                                                </button>
                                                <button
                                                    style={{ padding: "0px 0px", borderWidth: "0px" }}
                                                    onClick={() => { setShowTabularChart(true); }}
                                                >
                                                    {!showTabularChart && showChart &&
                                                        <img src="images/tabular-toggle-unselected.png" alt="show tabular chart" />
                                                    }
                                                    {showTabularChart && showChart &&
                                                        <img src="images/tabular-toggle-selected.png" alt="show tabular chart" />
                                                    }
                                                </button>
                                            </>
                                            }
                                        </div>
                                        <div>
                                            <p>
                                                {/*f009 is when there is no filter*/}
                                                <b>Filter :</b> {questionData.FilterId != "F009" ? tableHeaderText.replace('Filter: ', '') : "No Filter"}
                                            </p>
                                        </div>
                                        {(showTabularChart || !showChart) &&
                                            <div style={{ textAlign: "center" }}>
                                                <p>
                                                    <b>{title}<br /></b>
                                                </p>
                                            </div>
                                        }
                                        <div
                                            className="result_table"
                                            style={{
                                                margin: "0 auto",
                                                position: "relative",
                                                overflow: "hidden",
                                                maxWidth: "100%",
                                                textAlign: "left",
                                                lineHeight: "normal",
                                                zindex: 0,
                                                WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                            }}
                                        >
                                            {Report}

                                            <div
                                                className="highcharts-tooltip"
                                                style={{
                                                    position: "absolute",
                                                    left: "242px",
                                                    top: "-9999px",
                                                    visibility: "visible",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        position: "absolute",
                                                        whiteSpace: "nowrap",
                                                        fontFamily: "Arial, Helvetica, sans-serif",
                                                        fontSize: "12px",
                                                        color: "rgb(51, 51, 51)",
                                                        marginLeft: "0px",
                                                        marginTop: "0px",
                                                        left: "0px",
                                                        top: "0px",
                                                    }}
                                                    zindex="1"
                                                >
                                                    <div className="rdquicktip-content">
                                                        <div className="body">
                                                            <table className="rdquicktip-table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td></td>
                                                                        <td id="tooltipText">
                                                                            35-37 <br /> Age of patient <br /> 15.6
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                        <div dangerouslySetInnerHTML={{ __html: !showChart ? tableFooterText.replace("d-none", "") : tableFooterText }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
}; // .mapStateToProps

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tab2)