import { useState } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';
import BarChartVertical from "../charts/BarChartVertical";
import BarChartVerticalSmall from "../charts/BarChartVerticalSmall";
import BarChartHorizontal from "../charts/BarChartHorizontal";
import TabularChart from "../charts/TabularChart";
import TabularChartReason from "../charts/TabularChartReason";
import { Spinner } from "../spinner/Spinner";
import { Modal, Button } from 'react-bootstrap';
import { generate_New_Filter_ArrayObj } from "../../util";



function Tab1({ user, actions, isLoading, tables }) {
    //console.log(tables);
    const filterOption2_1 = generate_New_Filter_ArrayObj(tables.Questions, 0, 210);

    const [filterVal2, setFilterVal2] = useState(0);
    const [showTabularChart, setShowTabularChart] = useState(false);
    //setting show description text container
    const [truncateText1, setTruncateText1] = useState(true);
    const [truncateText2, setTruncateText2] = useState(true);

    //setting modal
    const [visibleFilterMeta, setVisibleFilterMeta] = useState(false);
    const btnCloseFilterMeta = () => setVisibleFilterMeta(false);
    const btnShowFilterMeta = () => setVisibleFilterMeta(true);

    if (isLoading) {
        return (
            <div>
                <>
                    <Spinner
                        message="Loading Data..."
                        backGround={true}
                        center={true}
                    />
                </>
            </div>
        )
    }

    const changeFilter = (index2) => {
        setFilterVal2(index2);
    };


    const getSelectBoxText = () => {
        var useFilterOption = filterOption2_1;
        return useFilterOption[filterVal2].text;
    }

    let resultBotDivCSS = { display: "flex" };

    const questions = tables.Questions
    if (typeof (questions) == 'undefined' || questions == null) return <p>Error: cannot find Questions.</p>;
    // console.log(questions)
    for (let i = 0; i < questions.length; i++) {
        let question = questions[i]
        let questOption1 = filterOption2_1.find(questOption => questOption.value === question.QuestionsId)
        if (questOption1) questOption1.text = questions[i].Question
        else {
            console.log('Warning: unexpected question found ' + question.QuestionsId)
        }
        // console.log(question)
    }
    // console.log(filterOption2_1)
    // console.log(filterOption2_2)

    var useFilterOption = filterOption2_1;
    //console.log(useFilterOption);
    let option2List = useFilterOption.map((filter, index) => {
        // to see questioIO, use: {filter.value} {filter.text}       
        return (
            <option key={filter.value} value={index}>
                {filter.text}
            </option>
        )
    });

    const questionId = `${useFilterOption[filterVal2].value}`,
        questionData = tables.Data.find(question => question.QuestionId === questionId),
        showChart = questionId === "Q002" ? true : (questionData.DataRows[0][5] === 'True'); //q002 will always be true, that's why this condition is here

    if (typeof (questionData) == 'undefined' || questionData == null) return <p>Error: cannot find data for question.</p>;

    const questionKey = questionId
    // const tableHeaderText = questionData.TableHeader
    var tableFooterText = questionData.TableFooter
    for (var i = tableFooterText.length - 1, c = 0; i >= 0; i--) {
        if (tableFooterText.charAt(i) >= '0' && tableFooterText.charAt(i) <= '9') {
            c++
        } else {
            c = 0
        }
        if (c === 4) {
            tableFooterText = tableFooterText.substring(0, i + 1) + ',' + tableFooterText.substring(i + 1)
            break;
        }
    }

    let Report;
    let title = 'Age of Patient'

    switch (questionId) {
        case "Q002":
            //alert('horizontal ' + questionId)
            title = 'Reason'
            if (!showTabularChart && showChart) {
                Report =
                    <BarChartHorizontal
                        data={questionData}
                        key={questionKey}
                    />
            } else {
                Report =
                    <TabularChartReason
                        data={questionData}
                        key={questionKey}
                        showFractional={false}
                    />
            }
            break;
        case "Q001":
        case "Q004":
        case "Q003":
        case "Q005":
        case "Q006":
        case "Q101":
        case "Q102":
        case "Q007":
        case "Q008":
        case "Q009":
        case "Q010":
        case "Q110":
            //alert('verticalPercent ' + questionId)
            if (!showTabularChart && showChart) {
                Report =
                    <BarChartVertical
                        data={questionData}
                        key={questionKey}
                        title={title}
                    />
            } else {
                Report =
                    <TabularChart
                        data={questionData}
                        key={questionKey}
                        dataRowsFormat='tab1'
                        showFractional={false}
                    />
            }
            break;
        default: //"Q210":
            //alert('verticalSmall ' + questionId)
            if (!showTabularChart) {
                Report =
                    <BarChartVerticalSmall
                        data={questionData}
                        key={questionKey}
                    />
            } else {
                Report =
                    <TabularChart
                        data={questionData}
                        key={questionKey}
                        dataRowsFormat='tab1NoPercent'
                        showFractional={false}
                    />
            }
            break;
    }

    return (
        <div className="tab_in pt-2 px-5">
            <Modal show={visibleFilterMeta} onHide={btnCloseFilterMeta} className="metaDataTableModal" aria-labelledby="metaDataTableModal">
                <Modal.Header>
                    <Modal.Title id="metaDataTableModal">Descriptions of Reasons</Modal.Title>
                    <Button onClick={btnCloseFilterMeta} className="bg-transparent border-0 text-dark">
                        X
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ height: "600px", overflow: "auto" }} tabIndex="0">
                        <table>
                            <thead>
                                <tr>
                                    <th className="h5 py-3">Name</th>
                                    <th className="h5 py-3">Description</th>
                                </tr>
                            </thead>
                            <tbody className="table table-bordered bl-0" >
                                {tables.Reasons && tables.Reasons.map((row, index) => (
                                    <tr key={index} >
                                        <td className="small">{row.Reason}</td>
                                        <td className="small">{row.ReasonDescription}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={btnCloseFilterMeta}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <div id="tab2Title1" className={truncateText1 ? "truncate-overflow" : ""} >
                {tables.TopicDescription[0].TopicDescription}
            </div>
            <div className="d-print-none text-right mt-1">
                <a
                    href="#/"
                    onClick={() => setTruncateText1(!truncateText1)}
                >
                    {truncateText1 ? "Show full description" : "Hide full description"}
                    <i className={`align-middle ml-1 fa fa-2x ${truncateText1 ? "fa-angle-down" : "fa-angle-up"}`}></i>
                </a>
            </div>

            <div className="bordered_grey_bot">
                <div className="top">
                    <div className="columns">
                        <p className="mb-1">
                            <b id="lblTab2Question" className="d-print-none">Select a patient or cycle characteristic</b>
                        </p>
                        <div className="row">
                            <div className="column col-12">
                                <select
                                    id="tab2Question"
                                    className="form-control"
                                    value={filterVal2}
                                    key={filterVal2}
                                    onChange={(e) => {
                                        changeFilter(e.target.value);
                                    }}
                                    aria-labelledby="lblTab2Question"
                                >
                                    {option2List}
                                </select>
                            </div>
                            <div className="column col-12 font-italic is-end is-selfvcentered mt-3 d-print-none">
                                viewing {useFilterOption[filterVal2].index} of {useFilterOption.length}
                                <button
                                    className="btn btn-secondary is-small is-info ml-4 mr-2"
                                    disabled={
                                        useFilterOption[filterVal2].index ===
                                        1
                                    }
                                    onClick={() => {
                                        changeFilter(+filterVal2 - 1);
                                    }}
                                >
                                    Previous
                                </button>
                                <button
                                    className="btn btn-secondary is-small is-info"
                                    disabled={
                                        useFilterOption[filterVal2].index ===
                                        useFilterOption.length
                                    }
                                    onClick={() => {
                                        changeFilter(+filterVal2 + 1);
                                    }}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>
                    <p className={`mt-3 mb-2  ${truncateText2 ? "truncate-overflow" : ""} `} id="tab2Title2">
                        {useFilterOption[filterVal2].header}
                    </p>
                    {useFilterOption[filterVal2].header.length > 250 &&
                        <div className="d-print-none text-right mt-1">
                            <a
                                href="#/"
                                onClick={() => setTruncateText2(!truncateText2)}
                            >
                                {truncateText2 ? "Show full description" : "Hide full description"}
                                <i className={`align-middle ml-1 fa fa-2x ${truncateText2 ? "fa-angle-down" : "fa-angle-up"}`}></i>
                            </a>
                        </div>
                    }
                </div>

                <div className="bot">
                    <div id="pdfDiv" className="tab1PdfDiv">
                        <div className="columns">
                            <div className="column">
                                <div className="filter_result">
                                    <div
                                        className="filter_result_bot"
                                    // style={resultBotDivCSS}
                                    >
                                        <div style={resultBotDivCSS}>
                                            <div style={{ float: "left" }}>
                                                <p className="float-left mr-1">{getSelectBoxText()}</p>
                                                {useFilterOption[filterVal2].index === 2 && <button
                                                    className="bg-dark button js-modal-trigger question_btn text-white"
                                                    data-target="modal_help_1"
                                                    aria-haspopup="true"
                                                    onClick={btnShowFilterMeta}
                                                >
                                                    ?
                                                </button>
                                                }
                                            </div>
                                        </div>
                                        <div style={{ float: "right" }}>
                                            {showChart &&
                                                (<><button
                                                    style={{ padding: "0px 0px", borderWidth: "0px" }}
                                                    onClick={() => { setShowTabularChart(false); }}
                                                >
                                                    {!showTabularChart && showChart &&
                                                        < img src="images/chart-toggle-selected.png" alt="show chart" />
                                                    }
                                                    {showTabularChart && showChart &&
                                                        <img src="images/chart-toggle-unselected.png" alt="show chart" />
                                                    }
                                                </button>

                                                    <button
                                                        style={{ padding: "0px 0px", borderWidth: "0px" }}
                                                        onClick={() => { setShowTabularChart(true); }}
                                                    >
                                                        {!showTabularChart && showChart &&
                                                            <img src="images/tabular-toggle-unselected.png" alt="show tabular chart" />
                                                        }
                                                        {showTabularChart && showChart &&
                                                            <img src="images/tabular-toggle-selected.png" alt="show tabular chart" />
                                                        }
                                                    </button></>)
                                            }
                                        </div>
                                        <div style={resultBotDivCSS}>
                                            <div style={{ float: "center" }}>
                                                <p>
                                                    <b> <br /></b>
                                                </p>
                                            </div>
                                        </div>
                                        {showTabularChart &&
                                            <div style={{ textAlign: "center" }}>
                                                <p>
                                                    <b>{title}<br /></b>
                                                </p>
                                            </div>
                                        }

                                        <div
                                            className="result_table"
                                            style={{
                                                margin: "0 auto",
                                                position: "relative",
                                                overflow: "hidden",
                                                maxWidth: "100%",
                                                textAlign: "left",
                                                lineHeight: "normal",
                                                zindex: 0,
                                                WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                            }}
                                        >
                                            {Report}

                                            <div
                                                className="highcharts-tooltip"
                                                style={{
                                                    position: "absolute",
                                                    left: "242px",
                                                    top: "-9999px",
                                                    visibility: "visible",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        position: "absolute",
                                                        whiteSpace: "nowrap",
                                                        fontFamily: "Arial, Helvetica, sans-serif",
                                                        fontSize: "12px",
                                                        color: "rgb(51, 51, 51)",
                                                        marginLeft: "0px",
                                                        marginTop: "0px",
                                                        left: "0px",
                                                        top: "0px",
                                                    }}
                                                    zindex="1"
                                                >
                                                    <div className="rdquicktip-content">
                                                        <div className="body">
                                                            <table className="rdquicktip-table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td></td>
                                                                        <td id="tooltipText">
                                                                            35-37 <br /> Age of patient <br /> 15.6
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>

                                        </div>
                                        <div dangerouslySetInnerHTML={{ __html: showTabularChart ? tableFooterText.replace("d-none", "") : tableFooterText }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
}; // .mapStateToProps

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tab1)