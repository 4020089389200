import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import * as actions from '../redux/actions';
import Cookies from 'universal-cookie';
import html2canvas from "html2canvas";

import Tab0 from "./tabs/Tab0";
import Tab1 from "./tabs/Tab1";
import Tab2 from "./tabs/Tab2";
import Tab3 from "./tabs/Tab3";
import Tab4 from "./tabs/Tab4";
import { doAnalyticsWithTimeout } from "../analytics";
import { GetEnvironment } from '../api'
import CDCbluePDFFooterIconBase64Url from "./CDCbluePDFFooterIconBase64Url"
import {jwtDecode as jwt_decode} from 'jwt-decode';
import { NoData } from "./NoData";
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner'
import Spinner from "./spinner/Spinner";


const TabPage = ({ user, actions }) => {
    const [tabIndex, setTabIndex] = useState(0);
    const navigate = useNavigate();
    var today = new Date()

    useEffect(() => {
        //const cookies = new Cookies(),
        let skipAuth = process.env.REACT_APP_AUTH_BYPASS === 'true',
            params = new URLSearchParams(window.location.search);

        let environment = GetEnvironment(),
            ClinicId = "",
            Year = "";
        // Expiration;

        //get and decode jwt token
        try {
            let jwtToken = params.get('ncdjwt'),
                decodedToken = jwt_decode(jwtToken);

            //get clinicid, year and expiration 
            ClinicId = decodedToken.ClinicId;
            Year = decodedToken.Year;
            //Expiration = decodedToken.exp;
        } catch (e) {
            console.log(e)
        }

        //method to set clinicid and year to session. It was set to a cookie, but was causing issues so using session instead. If cookie is needed, uncomment the code. 
        function setCookieFromJWT() {
            if (ClinicId != "" && Year != "") {
                sessionStorage.setItem('ClinicId', ClinicId)
                sessionStorage.setItem('Year', Year)
            }
        }

        //if dev or local, allow direct access to the app with clinicid and year in the url
        if (environment === "dev" || environment == "localhost") {
            //bypassing sams ONLY in dev
            skipAuth = 'true';

            let _clinicid = params.get("clinicid"),
                _year = params.get("year");

            //if no jwt token
            if (params.has('clinicid') === true && params.has('year') === true) { //using direct access
                console.log("DEV SAMS Bypass: Query Parameters Found: ", _clinicid, _year)
                sessionStorage.setItem('ClinicId', _clinicid)
                sessionStorage.setItem('Year', _year)

                //clear query string to prevent double reload, work locally but not in dev
                navigate("/#")
            } else if (Year === undefined || Year === 'null' || Year === null || Year === "" || ClinicId === undefined || ClinicId === 'null' || ClinicId === null || ClinicId === "") {
                console.log("DEV SAMS Bypass: No JWT Token or query parameters found...")
                //_clinicid = "377";
                _clinicid = "100";
                _year = "2021";


                sessionStorage.setItem('ClinicId', _clinicid)
                sessionStorage.setItem('Year', _year)
                console.log("DEV SAMS Bypass: Setting hardcoded clinicId and year.", _clinicid + ' ' + _year);

            } else {
                console.log('Setting JWT Token')
                setCookieFromJWT(); //using jwt
                console.log('Setting JWT Token Completed:', sessionStorage.getItem('ClinicId'), sessionStorage.getItem('Year'))

                //clear query string to prevent double reload, work locally but not in dev
                navigate("/#")
            }
        } else { //all other environments will reject direct access to the app(int, prod)
            setCookieFromJWT()
        }

        //SAMS authenication for each envrionment 
        if (!user.email) {
            if (!skipAuth) {
                var SAMSCallbackURL = "",
                    SAMSClinicId = "";

                if (environment === "dev") {
                    SAMSCallbackURL = "https://clinic-dashboard-ui-nass-clinic-dashboard.apps.ecpaas-dev.cdc.gov/samscallback";
                    SAMSClinicId = "bfab691b-91c3-4c1a-8ba4-3a6daf986a90";
                    window.location.replace("https://apigw-stg.cdc.gov:8443/auth/oauth/v2/authorize?client_id=" + SAMSClinicId + "&redirect_uri=" + SAMSCallbackURL + "&response_type=code&scope=openid+profile+email");
                }
                if (environment === "qa") {
                    SAMSCallbackURL = "https://artreportingdashboard-test.cdc.gov/samscallback";
                    SAMSClinicId = "bfab691b-91c3-4c1a-8ba4-3a6daf986a90";
                    window.location.replace("https://apigw-stg.cdc.gov:8443/auth/oauth/v2/authorize?client_id=" + SAMSClinicId + "&redirect_uri=" + SAMSCallbackURL + "&response_type=code&scope=openid+profile+email");
                }
                if (environment === "int") {
                    SAMSCallbackURL = "https://artreportingdashboard-int.cdc.gov/samscallback";
                    SAMSClinicId = "bfab691b-91c3-4c1a-8ba4-3a6daf986a90";
                    window.location.replace("https://apigw-stg.cdc.gov:8443/auth/oauth/v2/authorize?client_id=" + SAMSClinicId + "&redirect_uri=" + SAMSCallbackURL + "&response_type=code&scope=openid+profile+email");
                }
                if (environment === "prod") {
                    SAMSCallbackURL = "https://artreportingdashboard.cdc.gov/samscallback";
                    SAMSClinicId = "7489f337-6620-4584-82d8-115b23e0ecad";
                    window.location.replace("https://apigw.cdc.gov/auth/oauth/v2/authorize?client_id=" + SAMSClinicId + "&redirect_uri=" + SAMSCallbackURL + "&response_type=code&scope=openid+profile+email");
                }
            }

            //local host testing for sams
            //window.location.replace("https://apigw-stg.cdc.gov:8443/auth/oauth/v2/authorize?client_id=bfab691b-91c3-4c1a-8ba4-3a6daf986a90&redirect_uri=http://localhost:3000/samscallback&response_type=code&scope=openid+profile+email");
        }
    }, [user, actions]);

    const [hasData, setHasData] = useState(false);
    const [homePageLink, setHomePageLink] = useState("");
    const [fetchYear, setFetchYear] = useState(0);
    const [tab0_tables, tab0_setTables] = useState([]);
    const [tab0_isLoading, tab0_setIsLoading] = useState(true);
    const [tab1_tables, tab1_setTables] = useState([]);
    const [tab1_isLoading, tab1_setIsLoading] = useState(true);
    const [tab2_tables, tab2_setTables] = useState([]);
    const [tab2_isLoading, tab2_setIsLoading] = useState(true);
    const [tab3_tables, tab3_setTables] = useState([]);
    const [tab3_isLoading, tab3_setIsLoading] = useState(true);
    const [tab4_tables, tab4_setTables] = useState([]);
    const [tab4_isLoading, tab4_setIsLoading] = useState(true);



    AbortSignal.timeout ??= function timeout(ms) {
        const ctrl = new AbortController()
        setTimeout(() => ctrl.close(), ms)
        return ctrl.signal
    }

    useEffect(() => {
        tab0_setIsLoading(true);
        let year,
            clinicId;

        //SAMS bypass in dev, this was done to fix WI issues from SA team
        if (GetEnvironment() === 'dev' || GetEnvironment() === 'localhost') {
            console.log("DEV SAMS Bypass: Setting parameters initialized...")
            clinicId = sessionStorage.getItem('ClinicId');
            year = sessionStorage.getItem('Year');
            console.log("DEV SAMS Bypass: Completed setting parameters (", clinicId + ' ' + year + ")");
        } else { //if not dev or localhost, use the sams object to grab year and clinicid
            year = user.year;
            clinicId = user.clinicid;
        }


        let fetchUriPrefix = process.env.REACT_APP_FETCH_URI_PREFIX
        if (fetchUriPrefix == null) {
            fetchUriPrefix = ''
        } else {
            if (GetEnvironment() != 'localhost') {
                year = process.env.REACT_APP_FETCH_YEAR
                clinicId = process.env.REACT_APP_FETCH_CLINIC_ID
            }
        }
        setFetchYear(year)
        fetch(fetchUriPrefix + "/api/ServicesAndProfiles/years/" + year + "/clinics/" + clinicId)
            .then((response) => response.json())
            .then((response) => {
                tab0_setTables(response);
                response.ClinicInfo.FacilityName === "" ? setHasData(false) : setHasData(true);
                tab0_setIsLoading(false);
            });

        fetch(fetchUriPrefix + "/api/PatientAndCycle/years/" + year + "/clinics/" + clinicId)
            .then((response) => response.json())
            .then((response) => {
                setTimeout(() => {
                    tab1_setTables(response);
                    tab1_setIsLoading(false);
                }, 500) //delay to give time to load tab 2, without this, tab2 will bomb out. Will revisit reason at a later time. 
            });

        fetch(fetchUriPrefix + "/api/PatientAndCycle/years/" + year + "/clinics/" + clinicId)
            .then((response) => response.json())
            .then((response) => {
                setTimeout(() => {
                    tab1_setTables(response);
                    tab1_setIsLoading(false);
                }, 500);
            });

        //fetch(fetchUriPrefix + "/api/SuccessRateOwnEggs/years/" + year + "/clinics/" + clinicId)
        //    .then((response) => response.json())
        //    .then((response) => {
        //        setTimeout(() => {
        //            tab2_setTables(response);
        //            tab2_setIsLoading(false);
        //        }, 500) //biggest data load, becareful if you want to remove this
        //    });

        fetch(fetchUriPrefix + "/api/SuccessRateOwnEggs/years/" + year + "/clinics/" + clinicId, { signal: AbortSignal.timeout(300000) }) //5minutes in ms
            .then((response) => response.json())
            .then((response) => {
                setTimeout(() => {
                    tab2_setTables(response);
                    tab2_setIsLoading(false);
                }, 500)
            });

        fetch(fetchUriPrefix + "/api/SuccessRateDonorEggs/years/" + year + "/clinics/" + clinicId)
            .then((response) => response.json())
            .then((response) => {
                tab3_setTables(response);
                tab3_setIsLoading(false);
            });

        fetch(fetchUriPrefix + "/api/Summary/years/" + year + "/clinics/" + clinicId)
            .then((response) => response.json())
            .then((response) => {
                setTimeout(() => {
                    tab4_setTables(response);
                    tab4_setIsLoading(false);
                }, 500)
            });

        //redirect link back to art reporting
        fetch(fetchUriPrefix + "/api/GetEnv/GetEnvConfigInfo").then((response) => response.json())
            .then((response) => {
                let environment = GetEnvironment(),
                    artReportingHomePageLink = "";


                switch (environment) {
                    case "dev":
                        artReportingHomePageLink = "https://artreportingdev.cdc.gov/Project/Clinic/DashboardCallBack.aspx?year=" + year;
                        break;
                    case "qa":
                        artReportingHomePageLink = "https://artreportingtest.cdc.gov/Project/Clinic/DashboardCallBack.aspx?year=" + year;
                        break;
                    case "int":
                        artReportingHomePageLink = "https://artreportingtest.cdc.gov/Project/Clinic/DashboardCallBack.aspx?year=" + year;
                        break;
                    case "prod": //is soft prod or regular prod
                        if (response.IsSoftProd) {
                            artReportingHomePageLink = "https://artreportingprod.cdc.gov/Project/Clinic/DashboardCallBack.aspx?year=" + year;
                        } else {
                            artReportingHomePageLink = "https://artreporting.cdc.gov/Project/Clinic/DashboardCallBack.aspx?year=" + year
                        }
                        break;
                    default:
                        artReportingHomePageLink = "https://artreportingdev.cdc.gov/Project/Clinic/DashboardCallBack.aspx?year=" + year;
                    // default code block
                }
                setHomePageLink(artReportingHomePageLink)

            });

        //analytics
        doAnalyticsWithTimeout({
            levels: ["Chronic", "DRH", "surveillance", "NASS"],
            AzEntry: null,
            channel: "DRH_NASS_CD"
        });
    }, [user.clinicid, user.year]);

    function changeTabHandler(index) {
        setTabIndex(index)
    }

    const getImgElement = async (input, imgWidth = 900, altText) => {
        var img,
            _tabIndex = tabIndex;
        await html2canvas(input, {
            onclone: (document) => {
                // console.log(input)
            },
        }).then((canvas) => {
            const image = { type: "jpeg", quality: 0.98 };
            let _imgWidth = '900';
            // const margin = [0.5, 0.5];

            // var imgWidth = 8.5;
            // var pageHeight = 11;

            // var innerPageWidth = imgWidth - margin[0] * 2;
            // var innerPageHeight = pageHeight - margin[1] * 2;

            // Calculate the number of pages.
            var pxFullHeight = canvas.height;
            //console.log(pxFullHeight)
            // var pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
            // var nPages = Math.ceil(pxFullHeight / pxPageHeight);

            // Define pageHeight separately so it can be trimmed on the final page.
            // pageHeight = innerPageHeight;

            // Create a one-page canvas to split up the full image.
            var pageCanvas = document.createElement("canvas");
            var pageCtx = pageCanvas.getContext("2d");
            pageCanvas.width = canvas.width;
            pageCanvas.height = pxFullHeight; //pxPageHeight;

            var page = 0;
            // for (var page = 0; page < nPages; page++) {
            // Trim the final page to reduce file size.
            //if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
            pageCanvas.height = pxFullHeight; // % pxPageHeight;
            // pageHeight = (pageCanvas.height * innerPageWidth) / pageCanvas.width;
            //}

            // Display the page.
            var w = pageCanvas.width;
            var h = pageCanvas.height;
            pageCtx.fillStyle = "white";
            pageCtx.fillRect(0, 0, w, h);
            pageCtx.drawImage(canvas, 0, page * pxFullHeight, w, h, 0, 0, w, h); // was page * pxPageHeight

            var imgData = pageCanvas.toDataURL(
                "image/" + image.type,
                image.quality
            );

            img = document.createElement('img');
            img.setAttribute("alt", altText)
            img.src = imgData;
            switch (true) {
                case _tabIndex === 3:
                    _imgWidth = '800'
                    break;
                case _tabIndex === 4:
                    _imgWidth = imgWidth
                default:
            }
            img.width = _imgWidth;
        });
        return img
    }

    const saveToPDFHandler = async () => {
        // return if tab is NOT loaded
        if (tabIndex === 0 && tab0_isLoading) return
        if (tabIndex === 1 && tab1_isLoading) return
        if (tabIndex === 2 && tab2_isLoading) return
        if (tabIndex === 3 && tab3_isLoading) return
        if (tabIndex === 4 && tab4_isLoading) return

        //console.log('saveToPDFHandler: start')

        // start spinner
        let fetchUriPrefix = process.env.REACT_APP_FETCH_URI_PREFIX
        if (fetchUriPrefix == null) {
            fetchUriPrefix = ''
        }

        var parentDiv = document.createElement("div"),
            pdfImgAltText = "";
        const titleARTData = document.createElement("div")

        //update pdf main heading with tab labels
        const tabMainHeading = "NASS Clinic Dashboard: "
        switch (true) {
            case tabIndex === 0:
                titleARTData.innerText = tabMainHeading + document.getElementById("linkTab0").innerText
                pdfImgAltText = document.querySelectorAll('#tabs-nav li')[0].innerText
                break;
            case tabIndex === 1:
                titleARTData.innerText = tabMainHeading + document.getElementById("linkTab1").innerText
                pdfImgAltText = document.querySelectorAll('#tabs-nav li')[1].innerText
                break;
            case tabIndex === 2:
                titleARTData.innerText = tabMainHeading + document.getElementById("linkTab2").innerText
                pdfImgAltText = document.querySelectorAll('#tabs-nav li')[2].innerText
                break;
            case tabIndex === 3:
                titleARTData.innerText = tabMainHeading + document.getElementById("linkTab3").innerText
                pdfImgAltText = document.querySelectorAll('#tabs-nav li')[3].innerText
                break;
            case tabIndex === 4:
                titleARTData.innerText = tabMainHeading + document.getElementById("linkTab4").innerText
                pdfImgAltText = document.querySelectorAll('#tabs-nav li')[4].innerText
                break;
            default:
            // code block
        }

        titleARTData.style.cssText = "margin-left:40px;font-family:arial;font-weight:bold;font-size:1.3rem;margin-bottom:1rem;margin-top:1.5rem"
        parentDiv.appendChild(titleARTData)

        const inputFacility = document.getElementById("pdfDivFacilityName");
        const imgFaciility = await getImgElement(inputFacility, 900, "Facility Name")
        imgFaciility.style.cssText = "margin-left:40px;margin-bottom:1rem"
        parentDiv.appendChild(imgFaciility)


        //tab description
        if (tabIndex === 1 || tabIndex === 2 || tabIndex === 3 || tabIndex === 4) {
            const tab1TableHeaderPara = document.getElementById("tab2Title1");
            const tab1TableHeader = document.createElement("div")
            //tab1TableHeader.innerText = tab1TableHeaderPara.innerHTML.replace("<br><br>", "\n\n")
            tab1TableHeader.innerText = tab1TableHeaderPara.innerHTML.replaceAll("<br>", "\n")
            tab1TableHeader.style.cssText = "font-family:arial;font-size:1.1rem;margin-bottom:1rem;margin-top:1rem;margin-left:40px;margin-right:60px"
            parentDiv.appendChild(tab1TableHeader)
        }

        //selection 
        if (tabIndex === 1 || tabIndex === 2 || tabIndex === 3) {
            const tab1QuestionSelect = document.getElementById("tab2Question");
            const tab1Question = document.createElement("div")
            tab1Question.innerText = tab1QuestionSelect.options[tab1QuestionSelect.value].text
            tab1Question.style.cssText = "font-family:arial;font-weight:bold;font-size:1.3rem;margin-bottom:1rem;margin-top:1rem;margin-left:40px;margin-right:60px"
            parentDiv.appendChild(tab1Question)
        }

        //selection description
        if (tabIndex === 1 || tabIndex === 2 || tabIndex === 3) {
            const tab1DescriptionDiv = document.getElementById("tab2Title2");
            const tab1Description = document.createElement("div")
            tab1Description.innerText = tab1DescriptionDiv.innerHTML
            tab1Description.style.cssText = "font-family:arial;font-size:1.1rem;margin-bottom:1rem;margin-top:1rem;margin-left:40px;margin-right:60px"
            parentDiv.appendChild(tab1Description)
        }

        if (tabIndex === 4) {
            const inputTabTbl2 = document.getElementById("pdfDivTbl2"),
                imgTab2 = await getImgElement(inputTabTbl2, "900", "Cumulative Art Success Rates for Intended Retrievals Among Patients Using Their Own Eggs:New patients (with no prior ART cycles)"),
                inputTabTbl3 = document.getElementById("pdfDivTbl3"),

                imgTab3 = await getImgElement(inputTabTbl3, "900", "Non-cumulative Art Success Rates for Transfers Among Patients Using Eggs or Embryos from a Donor a,b,c,d and Characteristics of ART Cycles a,b"),
                inputTabTbl4 = document.getElementById("pdfDivTbl4"),

                imgTab4 = await getImgElement(inputTabTbl4, "900", "Clinic Services Summary and Reason for Using ARTa,b,g"),
                inputTabTbl5 = document.getElementById("legendTextWrapper"),

                imgTab5 = await getImgElement(inputTabTbl5, "900", "Legend"),
                imgTabDiv1 = document.createElement("div"),

                imgTabDiv3 = document.createElement("div"),
                imgTabDiv4 = document.createElement("div"),
                imgTabDiv5 = document.createElement("div");

            imgTabDiv1.style.cssText = "vertical-align:middle;text-align:center; margin-right: 22px;"
            imgTabDiv1.appendChild(imgTab2)
            parentDiv.appendChild(imgTabDiv1)

            imgTabDiv3.style.cssText = "vertical-align:middle;text-align:center; margin-right: 22px; margin-top: 15em;"
            imgTabDiv3.appendChild(imgTab3)
            parentDiv.appendChild(imgTabDiv3)

            imgTabDiv4.style.cssText = "vertical-align:middle;text-align:center;margin-right: 22px; margin-top: 35em;"
            imgTabDiv4.appendChild(imgTab4)
            parentDiv.appendChild(imgTabDiv4)

            imgTabDiv5.style.cssText = "vertical-align:middle; margin-left: 40px;"
            imgTabDiv5.appendChild(imgTab5)
            parentDiv.appendChild(imgTabDiv5)

        } else if (tabIndex === 3) {
            const inputTab = document.getElementById("pdfDiv");
            const imgTab = await getImgElement(inputTab, "800", pdfImgAltText);
            let marginBottom = document.getElementById('tab2Title2').innerText.length > 200 && "margin-bottom: 5em;";
            const imgTabDiv = document.createElement("div")
            imgTabDiv.style.cssText = "vertical-align:middle;text-align:center;" + marginBottom;
            imgTabDiv.appendChild(imgTab)
            parentDiv.appendChild(imgTabDiv)
        } else {
            const inputTab = document.getElementById("pdfDiv");
            const imgTab = await getImgElement(inputTab, "900", pdfImgAltText)
            const imgTabDiv = document.createElement("div")
            imgTabDiv.style.cssText = "margin-left:40px;"
            imgTabDiv.appendChild(imgTab)
            parentDiv.appendChild(imgTabDiv)
        }

        const imgFooter = document.createElement("img")
        imgFooter.src = CDCbluePDFFooterIconBase64Url()
        imgFooter.style.cssText = "float:left;margin-right:10px"

        var today = new Date()
        const footerText = document.createElement("div")
        footerText.innerText = "Centers for Disease Control and Prevention, National Center for Chronic Disease Prevention and Health Promotion. Division of Reproductive Health[accessed " + today.toLocaleDateString() + "]."
        footerText.style.cssText = "font-family:arial;font-size:1.1rem;width:930px;margin-top:15px"

        const footer = document.createElement("div")
        footer.style.cssText = "overflow:hidden;margin-top:20px;margin-left:40px;"
        footer.appendChild(imgFooter)
        footer.appendChild(footerText)
        parentDiv.appendChild(footer)

        var visualTabIndex = tabIndex + 1
        const filename = "NASS_Clinic_Dasboard_Data_Tab_" + visualTabIndex
        const tabHTML = parentDiv.outerHTML // 'Troy tabHTML in body React POST Request Example'
        const downloadPDFBody = {
            tabHTML
        }

        try {
            // call api, copy code from email
            //const requestOptions = {
            //    method: 'POST',
            //    headers: { 'Content-Type': 'application/json' },
            //    body: JSON.stringify(downloadPDFBody)
            //};
            //const response = await fetch(fetchUriPrefix + "/api/DownloadPDF/", requestOptions);
            ////console.log('saveToPDFHandler: GET DownloadPDF')
            //const pdfBlob = await response.blob();

            //// Trigger the download in the browseer
            //const href = window.URL.createObjectURL(pdfBlob);
            //const link = document.createElement('a');
            //link.href = href;
            //link.setAttribute('download', filename + '.pdf'); //or any other extension
            //document.body.appendChild(link);
            //link.click();
            //document.body.removeChild(link);
            //console.log('PDF Downloaded')
            //media print
            window.print();
        } catch (error) {
            console.log('saveToPDFHandler Error: ', error)
        }
        console.log('saveToPDFHandler: end')
    }

    function currentTabHandler() {

        switch (tabIndex) {
            case 0:
                return <Tab0
                    year={fetchYear}
                    isLoading={tab0_isLoading}
                    tables={tab0_tables} />;
            case 1:
                return <Tab1
                    isLoading={tab1_isLoading}
                    tables={tab1_tables} />;
            case 2:
                return <Tab2
                    isLoading={tab2_isLoading}
                    tables={tab2_tables} />;
            case 3:
                return <Tab3
                    isLoading={tab3_isLoading}
                    tables={tab3_tables} />;
            default:
                return <Tab4
                    isLoading={tab4_isLoading}
                    tables={tab4_tables} />;
        }

    }

    var stateAbbr = {
        "Alabama": "AL",
        "Alaska": "AK",
        "American Samoa": "AS",
        "Arizona": "AZ",
        "Arkansas": "AR",
        "California": "CA",
        "Colorado": "CO",
        "Connecticut": "CT",
        "Delaware": "DE",
        "District of Columbia": "DC",
        "Federated States Of Micronesia": "FM",
        "Florida": "FL",
        "Georgia": "GA",
        "Guam": "GU",
        "Hawaii": "HI",
        "Idaho": "ID",
        "Illinois": "IL",
        "Indiana": "IN",
        "Iowa": "IA",
        "Kansas": "KS",
        "Kentucky": "KY",
        "Louisiana": "LA",
        "Maine": "ME",
        "Marshall Islands": "MH",
        "Maryland": "MD",
        "Massachusetts": "MA",
        "Michigan": "MI",
        "Minnesota": "MN",
        "Mississippi": "MS",
        "Missouri": "MO",
        "Montana": "MT",
        "Nebraska": "NE",
        "Nevada": "NV",
        "New Hampshire": "NH",
        "New Jersey": "NJ",
        "New Mexico": "NM",
        "New York": "NY",
        "North Carolina": "NC",
        "North Dakota": "ND",
        "Northern Mariana Islands": "MP",
        "Ohio": "OH",
        "Oklahoma": "OK",
        "Oregon": "OR",
        "Palau": "PW",
        "Pennsylvania": "PA",
        "Puerto Rico": "PR",
        "Rhode Island": "RI",
        "South Carolina": "SC",
        "South Dakota": "SD",
        "Tennessee": "TN",
        "Texas": "TX",
        "Utah": "UT",
        "Vermont": "VT",
        "Virgin Islands": "VI",
        "Virginia": "VA",
        "Washington": "WA",
        "West Virginia": "WV",
        "Wisconsin": "WI",
        "Wyoming": "WY",
        "None": "NA"
    },
        stateDescription = "";

    let stateImage = "";
    try {
        if (!tab0_isLoading) {
            stateImage = "images/state/Shared.Images.Purple." +
                stateAbbr[tab0_tables.ClinicInfo.StateDesc].toLowerCase() + ".png";
            stateDescription = tab0_tables.ClinicInfo.StateDesc;
        }
    } catch (er) {
        console.log(er)
    }


    const tabTitles = [
        "Clinic Services & Profile",
        "Patient & Cycle Characteristics",
        "Success Rates: Patients Using Own Eggs",
        "Success Rates: Patients Using Donor Eggs",
        "Clinic Data Summary",
    ];

    return (
        <>
            <main>
                <div className="container pl-0 py-2">
                    {hasData && <a id="artReportingHomePageLink" className="d-print-none" href={homePageLink}>Return to Submission Process</a>}
                </div>

                <div className="container d-flex flex-wrap body-wrapper bg-white">
                    <section className="row col-12">
                        <div className="col-12">
                            <h1 class="pageHeading d-print-none">Your Clinic's ART Data</h1>
                            <div className="social_share row col-12 d-print-none">
                                <a href="/#">
                                    <img src="images/facebook-32.png" alt="facebook" />
                                </a>
                                <a href="/#">
                                    <img src="images/twitter-32.png" alt="twitter" />
                                </a>
                                <div className="dropdown">
                                    <div className="dropdown-trigger">
                                        <button
                                            className="button is-nothing"
                                            aria-haspopup="true"
                                            aria-controls="dropdown-menu"
                                        >
                                            <img src="images/addthis-32.png" alt="add this" />
                                        </button>
                                    </div>
                                    <div className="dropdown-menu" id="dropdown-menu" role="menu">
                                        <div className="dropdown-content">
                                            <a href="/#" className="dropdown-item">
                                                <img
                                                    className="facebook"
                                                    src="images/facebook-16.png"
                                                    alt="facebook"
                                                />
                                                facebook
                                            </a>
                                            <a href="/#" className="dropdown-item">
                                                <img
                                                    className="twitter"
                                                    src="images/twitter-16.png"
                                                    alt="twitter"
                                                />
                                                twitter
                                            </a>
                                            <a href="/#" className="dropdown-item">
                                                <img
                                                    className="linkedin"
                                                    src="images/linkedin-16.png"
                                                    alt="linkedin"
                                                />
                                                linkedin
                                            </a>
                                            <a href="/#" className="dropdown-item">
                                                <img
                                                    className="email"
                                                    src="images/email-16.png"
                                                    alt="Email"
                                                />
                                                Email
                                            </a>
                                            <a href="/#" className="dropdown-item">
                                                <img
                                                    className="digg"
                                                    src="images/digg-16.png"
                                                    alt="digg"
                                                />
                                                digg
                                            </a>
                                            <a href="/#" className="dropdown-item">
                                                Add this to your site
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {hasData &&
                                (
                                    <div className="columns mt-2">
                                        <div style={{ float: "right" }}>
                                            <div className="d-print-none">
                                                <div className="links_block">
                                                    <a href="/#" onClick={saveToPDFHandler}>
                                                        <img
                                                            className="ico_pdf"
                                                            src="images/Shared.Images.pdf_icon.gif"
                                                            alt="pdf"
                                                        />
                                                        Save as PDF (.pdf)
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tabName h3 d-none">NASS Clinic Dashboard: {tabTitles[tabIndex]}</div>

                                        <div id="pdfDivFacilityName" className="column is-size-4 is-selfvcentered" style={{ fontSize: "1.5rem" }}>
                                            <img
                                                className="pr-3"
                                                src={stateImage}
                                                alt={stateDescription}
                                            />
                                            {!tab0_isLoading && tab0_tables.ClinicInfo && tab0_tables.ClinicInfo.FacilityName}
                                        </div>
                                    </div>
                                )
                            }
                            {
                                !tab0_isLoading && !hasData ? <NoData homeLink={homePageLink} /> : <div className="tabs is-boxed border is-boxed p-1 rounded tabs_block">
                                    <ul className="nav nav-tabs bg-gray-l1 pt-2 d-print-none" id="tabs-nav">
                                        <li
                                            className={`${tabIndex === 0 ? "active" : ""} nav-item mb-0 rounded-top mr-2`}
                                        >

                                            {tab0_isLoading
                                                ? <a href="/#" className='active nav-link bg-gray-l3 pt-3'>
                                                    <Oval
                                                        height={20}
                                                        width={20}
                                                        color="#712177"
                                                        wrapperStyle={{ "display": "block", "position": "absolute", "top": "13px" }}
                                                        wrapperClass=""
                                                        visible={true}
                                                        ariaLabel='oval-loading'
                                                        secondaryColor="#dee2e6"
                                                        strokeWidth={2}
                                                        strokeWidthSecondary={2}

                                                    /> Loading Data... </a>
                                                : <a id="linkTab0" href="/#" className={`${tabIndex === 0 ? "active" : ""} nav-link bg-gray-l3`} onClick={() => changeTabHandler(0)}>
                                                    Clinic Services & Profile
                                                </a>}

                                        </li>
                                        <li
                                            className={`${tabIndex === 1 ? "active  font-weight-bold" : ""} nav-item mb-0 rounded-top mr-2`}
                                        >
                                            {tab1_isLoading
                                                ? <a href="/#" className='nav-link bg-gray-l3 pt-3'>
                                                    <Oval
                                                        height={20}
                                                        width={20}
                                                        color="#712177"
                                                        wrapperStyle={{ "display": "block", "position": "absolute", "top": "13px" }}
                                                        wrapperClass=""
                                                        visible={true}
                                                        ariaLabel='oval-loading'
                                                        secondaryColor="#dee2e6"
                                                        strokeWidth={2}
                                                        strokeWidthSecondary={2}

                                                    /> Loading Data... </a>
                                                : <a id="linkTab1" href="/#" className={`${tabIndex === 1 ? "active" : ""} nav-link bg-gray-l3`} onClick={() => changeTabHandler(1)}>
                                                    Patient & Cycle Characteristics
                                                </a>}

                                        </li>
                                        <li
                                            className={`${tabIndex === 2 ? "active  font-weight-bold" : ""} nav-item mb-0 rounded-top mr-2`}
                                        >
                                            {tab2_isLoading
                                                ? <a href="/#" className='nav-link bg-gray-l3 pt-3'>
                                                    <Oval
                                                        height={20}
                                                        width={20}
                                                        color="#712177"
                                                        wrapperStyle={{ "display": "block", "position": "absolute", "top": "13px" }}
                                                        wrapperClass=""
                                                        visible={true}
                                                        ariaLabel='oval-loading'
                                                        secondaryColor="#dee2e6"
                                                        strokeWidth={2}
                                                        strokeWidthSecondary={2}

                                                    /> Loading Data... </a>
                                                : <a id="linkTab2" href="/#" className={`${tabIndex === 2 ? "active" : ""} nav-link bg-gray-l3`} onClick={() => changeTabHandler(2)}>
                                                    Success Rates: Patients Using Own Eggs
                                                </a>}

                                        </li>
                                        <li
                                            className={`${tabIndex === 3 ? "active  font-weight-bold" : ""} nav-item mb-0 rounded-top mr-2`}
                                        >
                                            {tab3_isLoading
                                                ? <a href="/#" className='nav-link bg-gray-l3 pt-3'>
                                                    <Oval
                                                        height={20}
                                                        width={20}
                                                        color="#712177"
                                                        wrapperStyle={{ "display": "block", "position": "absolute", "top": "13px" }}
                                                        wrapperClass=""
                                                        visible={true}
                                                        ariaLabel='oval-loading'
                                                        secondaryColor="#dee2e6"
                                                        strokeWidth={2}
                                                        strokeWidthSecondary={2}

                                                    /> Loading Data... </a>
                                                : <a id="linkTab3" href="/#" className={`${tabIndex === 3 ? "active" : ""} nav-link bg-gray-l3`} onClick={() => changeTabHandler(3)}>
                                                    Success Rates: Patients Using Donor Eggs
                                                </a>}
                                        </li>
                                        <li
                                            className={`${tabIndex === 4 ? "active  font-weight-bold" : ""} nav-item mb-0 rounded-top mr-2`}
                                        >
                                            {tab4_isLoading
                                                ? <a href="/#" className='nav-link bg-gray-l3 pt-3'>
                                                    <Oval
                                                        height={20}
                                                        width={20}
                                                        color="#712177"
                                                        wrapperStyle={{ "display": "block", "position": "absolute", "top": "13px" }}
                                                        wrapperClass=""
                                                        visible={true}
                                                        ariaLabel='oval-loading'
                                                        secondaryColor="#dee2e6"
                                                        strokeWidth={2}
                                                        strokeWidthSecondary={2}

                                                    /> Loading Data... </a>
                                                : <a id="linkTab4" href="/#" className={`${tabIndex === 4 ? "active" : ""} nav-link bg-gray-l3`} onClick={() => changeTabHandler(4)}>
                                                    Clinic Data Summary
                                                </a>}
                                        </li>
                                    </ul>
                                    <div className="tab-content small-font" id="tabContent">{currentTabHandler()}</div>

                                    <div className="container printFooter d-none">
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <img
                                                    src="/images/CDCbluePDFFooterIcon.jpg"
                                                    alt="My Image"
                                                    className="img-fluid"
                                                />
                                            </div>
                                            <div className="col">
                                                <p className="text-wrap">
                                                    Centers for Disease Control and Prevention, National Center for
                                                    Chronic Disease Prevention and Health Promotion. Division of
                                                    Reproductive Health [accessed {today.toLocaleDateString()}].
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </section>
                    <section className="row col-12 my-4 info-row d-print-none">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">Related Links</div>
                                <div className="card-body row">
                                    <div className="col-4">
                                        <ul>
                                            <li className="small mb-3">
                                                <a href="https://www.womenshealth.gov/about-us" className="tp-link-policy" target="_blank" rel="noreferrer">
                                                    HHS Office on Women's Health <span className="sr-only">external icon</span><span className="fi cdc-icon-external x16 fill-external" aria-hidden="true"></span>
                                                </a>
                                            </li>
                                            <li className="small">
                                                <a href="https://resolve.org/" target="_blank" rel="noreferrer">
                                                    Resolve <span className="sr-only">external icon</span><span className="fi cdc-icon-external x16 fill-external" aria-hidden="true"></span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-4">
                                        <ul>
                                            <li className="small">
                                                <a href="https://www.sart.org/" target="_blank" rel="noreferrer">
                                                    Society for Assisted Reproductive Technology (SART) <span className="sr-only">external icon</span><span className="fi cdc-icon-external x16 fill-external" aria-hidden="true"></span>
                                                </a>
                                            </li>
                                            <li className="small">
                                                <a
                                                    href="https://www.familyequality.org/family-building/path2parenthood/"
                                                    target="_blank" rel="noreferrer"
                                                >
                                                    Path 2 Parenthood <span className="sr-only">external icon</span><span className="fi cdc-icon-external x16 fill-external" aria-hidden="true"></span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-4">
                                        <ul>
                                            <li className="small"> <a href="https://www.asrm.org/" target="_blank" rel="noreferrer">
                                                American Society for Reproductive Medicine (ASRM) <span className="sr-only">external icon</span><span className="fi cdc-icon-external x16 fill-external" aria-hidden="true"></span>
                                            </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

            </main>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
}; // .mapStateToProps

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabPage)
