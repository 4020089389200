import React from "react";
import { returnDecimalNum } from "../../util";

const TabularChart = (props) => {
    const report = props.data;
    const dataRowsFormat = props.dataRowsFormat;
    const showFractional = props.showFractional;

    //console.log(report)

    var frac1suffix = ''
    if (showFractional && !report.DataRows[1].includes("%")) frac1suffix = <sup>¥</sup>
    var frac2suffix = ''
    if (showFractional && !report.DataRows[2].includes("%")) frac2suffix = <sup>¥</sup>
    var frac3suffix = ''
    if (showFractional && !report.DataRows[3].includes("%")) frac3suffix = <sup>¥</sup>
    var frac4suffix = ''
    if (showFractional && !report.DataRows[4].includes("%")) frac4suffix = <sup>¥</sup>

    return (
        <>
            <div className="table-container">
                <table
                    className="table is-hoverable is-fullwidth table-bordered tabularChartOne"
                    cellSpacing="1"
                >
                    <thead>
                        <tr>
                            <th className="th-tertiary" style={{ textAlign: "center", backgroundColor: "#e3d3e4" }}><span className="sr-only">Clinic</span></th>
                            <th className="th-tertiary" style={{ textAlign: "center", backgroundColor: "#e3d3e4" }}>{report.RowHeaders[1]}</th>
                            <th className="th-tertiary" style={{ textAlign: "center", backgroundColor: "#e3d3e4" }}>{report.RowHeaders[2]}</th>
                            <th className="th-tertiary" style={{ textAlign: "center", backgroundColor: "#e3d3e4" }}>{report.RowHeaders[3]}</th>
                            <th className="th-tertiary" style={{ textAlign: "center", backgroundColor: "#e3d3e4" }}>{report.RowHeaders[4]}</th>
                        </tr>
                    </thead>
                    {(dataRowsFormat === 'tab1') &&
                        <tbody>
                            <tr>
                                <td className="th-tertiary" style={{ backgroundColor: "#e3d3e4" }} key='0'><b>Clinic</b></td>
                                <td style={{ textAlign: "center" }} key='1' dangerouslySetInnerHTML={{ __html: returnDecimalNum(report.DataRows[0][1]) }}></td>
                                <td style={{ textAlign: "center" }} key='2' dangerouslySetInnerHTML={{ __html: returnDecimalNum(report.DataRows[0][2]) }}></td>
                                <td style={{ textAlign: "center" }} key='3' dangerouslySetInnerHTML={{ __html: returnDecimalNum(report.DataRows[0][3]) }}></td>
                                <td style={{ textAlign: "center" }} key='4' dangerouslySetInnerHTML={{ __html: returnDecimalNum(report.DataRows[0][4]) }}></td>
                            </tr>
                        </tbody>
                    }
                    {(dataRowsFormat === 'tab1NoPercent') &&
                        <tbody>
                            <tr>
                                <td className="th-tertiary" style={{ backgroundColor: "#e3d3e4" }} key='0'><b>Clinic</b></td>
                                <td style={{ textAlign: "center" }} key='1' dangerouslySetInnerHTML={{ __html: returnDecimalNum(report.DataRows[0][1]) }}></td>
                                <td style={{ textAlign: "center" }} key='2' dangerouslySetInnerHTML={{ __html: returnDecimalNum(report.DataRows[0][2]) }}></td>
                                <td style={{ textAlign: "center" }} key='3' dangerouslySetInnerHTML={{ __html: returnDecimalNum(report.DataRows[0][3]) }}></td>
                                <td style={{ textAlign: "center" }} key='4' dangerouslySetInnerHTML={{ __html: returnDecimalNum(report.DataRows[0][4]) }}></td>
                            </tr>
                        </tbody>
                    }
                    {(dataRowsFormat !== 'tab1' && dataRowsFormat !== 'tab1NoPercent' && !showFractional) &&
                        <tbody>
                            <tr>
                                <td className="th-tertiary" style={{ backgroundColor: "#e3d3e4" }} key='0'><b>Clinic</b></td>
                                <td style={{ textAlign: "center" }} key='1' dangerouslySetInnerHTML={{ __html: returnDecimalNum(report.DataRows[1], true)}}></td>
                                <td style={{ textAlign: "center" }} key='2' dangerouslySetInnerHTML={{ __html: returnDecimalNum(report.DataRows[2], true)}}></td>
                                <td style={{ textAlign: "center" }} key='3' dangerouslySetInnerHTML={{ __html: returnDecimalNum(report.DataRows[3], true)}}></td>
                                <td style={{ textAlign: "center" }} key='4' dangerouslySetInnerHTML={{ __html: returnDecimalNum(report.DataRows[4], true)}}></td>
                            </tr>
                        </tbody>
                    }
                    {(dataRowsFormat !== 'tab1' && dataRowsFormat !== 'tab1NoPercent' && showFractional) &&
                        <tbody>
                            <tr>
                                <td className="th-tertiary" style={{ backgroundColor: "#e3d3e4" }} key='0'><b>Clinic</b></td>
                                <td style={{ textAlign: "center" }} key='1' dangerouslySetInnerHTML={{__html: returnDecimalNum(report.DataRows[1])}}></td>
                                <td style={{ textAlign: "center" }} key='2' dangerouslySetInnerHTML={{__html: returnDecimalNum(report.DataRows[2])}}></td>
                                <td style={{ textAlign: "center" }} key='3' dangerouslySetInnerHTML={{__html: returnDecimalNum(report.DataRows[3])}}></td>
                                <td style={{ textAlign: "center" }} key='4' dangerouslySetInnerHTML={{__html: returnDecimalNum(report.DataRows[4])}}></td>
                            </tr>
                        </tbody>
                    }
                </table>
            </div>
        </>
    );
};

export default TabularChart;
