import React from "react";
import { returnDecimalNum } from "../../util";

const TabularChartReason = (props) => {
  const report = props.data;
  // const dataRowsFormat = props.dataRowsFormat;

  return (
    <>
      <div className="table-container">
          <table
            className="table is-hoverable is-fullwidth table-bordered tabularChartTwo"
            cellSpacing="1"
          >
            <thead>
              <tr>
                <th className="th-tertiary"  style={{textAlign: "center", backgroundColor: "#e3d3e4"}}>Factor</th>
                <th className="th-tertiary" style={{textAlign: "center", backgroundColor: "#e3d3e4"}}>Percentage</th>
              </tr>
            </thead>
            <tbody>
              {report.DataRows.map((row, key) => (
                    <tr key={key}>
                      {row.map((col, index) => {
                        if (index === 0) {
                            return <td className="th-tertiary" style={{backgroundColor: "#e3d3e4"}} key={col}><b>{col}</b></td>
                        } else {
                          return <td style={{textAlign: "center"}} key={col}>{returnDecimalNum(col)}</td>
                        }
                      })}
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
    </>
  );
};

export default TabularChartReason;
