//method to return new subset of questions with modified keys, this was done due to a code refactor from hardcoded values in the question description. 
export function generate_New_Filter_ArrayObj(arrObj, questionNumMin, questionNumMax) {
    let modifiedArrObj = [],
        _arrObj;

    try {
        _arrObj = arrObj.map(({ index, QuestionsId: value, text, Question, QuestionDescription: header }) => ({
            index,
            value,
            text,
            Question,
            header
        }));

        for (let x in _arrObj) {
            let _questionNum = Number(_arrObj[x].value.replace('Q', '')),
                isQuestionNumberValid = _questionNum >= questionNumMin & _questionNum <= questionNumMax; //remove the Q in Q011 to get subset of quesitions
            isQuestionNumberValid && modifiedArrObj.push(_arrObj[x])
        }

        for (let x in modifiedArrObj) {
            modifiedArrObj[x].index = Number(x) + 1;
            modifiedArrObj[x].text = modifiedArrObj[x].value + ' not found';
        }
    } catch (e) {
        //silet fail
    }


    return modifiedArrObj
}

//will return decimal percentage
export function returnDecimalNum(num, convertToDecimalPercentage = false, toDecimal = false, hideSymbol = false) {
    try {
        let _num = Number(num.toString().replace("%", "")),
            isPercentage = (num.toString().indexOf('%') != -1) ? true : false,
            isInt = (num.toString().indexOf('%') === -1
                && num.toString().indexOf("/") === -1
                && num.toString().indexOf(".") === -1) ? true : false,
            isFractional = num.toString().indexOf("/") !== -1 ? true : false,
            fractionSymbol = "<sup>&#165;</sup>"; //� symbol for fraction

        //return whole, percent or fraction results data
        if (isInt) {
            _num = convertToDecimalPercentage ? Number(num).toFixed(1) : toDecimal ? num + ".0" : num;
            _num = isPercentage ? _num + "%" : _num;
        } else if (Number.isInteger(_num)) {
            _num = _num + ".0" + (isPercentage ? "%" : "");
        } else if (isPercentage) {
            _num = convertToDecimalPercentage ? (num + "%").replace("%%", "%") : num
        } else if (isFractional) {
            _num = hideSymbol ? num : num + fractionSymbol;
        }
        return _num;
    } catch (err) {
        console.log(err)
    }

}