import { useState } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';
import { Spinner } from "../spinner/Spinner"
import { returnDecimalNum } from "../../util";

function Tab4({ user, actions, isLoading, tables }) {
    /*console.log(tables)*/
    //setting show description text container
    const [truncateText1, setTruncateText1] = useState(true);
    if (isLoading) {
        return (
            <div>
                <>
                    <Spinner
                        message="Loading Data..."
                        backGround={true}
                        center={true}
                    />
                </>
            </div>
        )
    }

    return (
        <div className="tab_in pt-2 px-5">
            <div id="tab2Title1" className={`mb-2 ${truncateText1 ? "truncate-overflow" : ""}`} dangerouslySetInnerHTML={{ __html: tables.Tab5Description.RowHeaders }}>
            </div>
            <div className="d-print-none text-right mt-1 mb-3">
                <a
                    href="#/"
                    onClick={() => setTruncateText1(!truncateText1)}
                >
                    {truncateText1 ? "Show full description" : "Hide full description"}
                    <i className={`align-middle ml-1 fa fa-2x ${truncateText1 ? "fa-angle-down" : "fa-angle-up"}`}></i>
                </a>
            </div>
            <div id="pdfDiv" className="bordered_grey">
                <div id="pdfDivTbl2">
                    <b className="d-block p-2 h5 my-0">
                        <div id="tab2Title2" className="font-weight-bold" dangerouslySetInnerHTML={{ __html: tables.Table2 && tables.Table2.TableHeader.replace(": All patients (with or without prior ART cycles)", "") }} />
                    </b>
                    <div className="table-container">
                        <table
                            className="is-striped table is-hoverable is-fullwidth table-bordered table-striped-light th_blue"
                            cellSpacing="1"
                        >
                            <thead>
                                <tr>
                                    <th id="thFactorTop" className="bg-primary border-0" scope="col"><span className="sr-only" >Factor</span></th>
                                    <th id="thPatientAgeTop" className="bg-primary border-0 text-center" scope="colgroup" colSpan="4">Patient Age</th>
                                </tr>
                                <tr aria-hidden="true">
                                    <th className="bg-primary border-0 " aria-hidden="true"></th>
                                    <th className="bg-primary border-0 text-center" aria-hidden="true">{tables.Table1 && tables.Table1.RowHeaders[1]}</th>
                                    <th className="bg-primary border-0 text-center" aria-hidden="true">{tables.Table1 && tables.Table1.RowHeaders[2]}</th>
                                    <th className="bg-primary border-0 text-center" aria-hidden="true">{tables.Table1 && tables.Table1.RowHeaders[3]}</th>
                                    <th className="bg-primary border-0 text-center" aria-hidden="true">{tables.Table1 && tables.Table1.RowHeaders[4]}</th>
                                </tr>

                            </thead>
                            <tbody>
                                <tr>
                                    <th id="allPatWoWArtCyles" className="bg-primary" colSpan="5" headers="thFactorTop" scope="colgroup">{tables.Table1.RowHeaders[0]}</th>
                                    <th className="sr-only" headers="thPatientAgeTop" scope="col">{tables.Table1 && tables.Table1.RowHeaders[1].replace("<", "less than ").replace(">", "greater than ")}</th>
                                    <th className="sr-only" headers="thPatientAgeTop" scope="col">{tables.Table1 && tables.Table1.RowHeaders[2].replace("<", "less than ").replace(">", "greater than ")}</th>
                                    <th className="sr-only" headers="thPatientAgeTop" scope="col">{tables.Table1 && tables.Table1.RowHeaders[3].replace("<", "less than ").replace(">", "greater than ")}</th>
                                    <th className="sr-only" headers="thPatientAgeTop" scope="col">{tables.Table1 && tables.Table1.RowHeaders[4].replace("<", "less than ").replace(">", "greater than ")}</th>
                                </tr>
                                {tables.Table1 && tables.Table1.DataRows && tables.Table1.DataRows.map((row) => (
                                    <tr className={row[5] ? "text-center bg-secondary-important" : "text-center"}>
                                        {row.map((col, index) => (
                                            index == 0 ?
                                                <th
                                                    className="text-left font-weight-normal"
                                                    headers="allPatWoWArtCyles"
                                                    scope="row"
                                                    dangerouslySetInnerHTML={{ __html: col }} /> :
                                                index != 5 && <td
                                                    headers="allPatWoWArtCyles"
                                                    className="text-center"
                                                    dangerouslySetInnerHTML={{ __html: (index !== 0 && row[0].indexOf("Average number of") === -1) ? returnDecimalNum(col, false, false, true) : col }} />
                                        ))}
                                    </tr>
                                ))}
                                <tr>
                                {/*console.log(tables.Table2.RowHeaders)*/}
                                    <th id="newPatientArtCyles" className="bg-primary" colSpan="5" scope="colgroup">{tables.Table2.RowHeaders[0]}</th>
                                    <th className="sr-only" headers="thFactorTop" scope="col">{tables.Table1 && tables.Table1.RowHeaders[1].replace("<", "less than ").replace(">", "greater than ")}</th>
                                    <th className="sr-only" headers="thFactorTop" scope="col">{tables.Table1 && tables.Table1.RowHeaders[2].replace("<", "less than ").replace(">", "greater than ")}</th>
                                    <th className="sr-only" headers="thFactorTop" scope="col">{tables.Table1 && tables.Table1.RowHeaders[3].replace("<", "less than ").replace(">", "greater than ")}</th>
                                    <th className="sr-only" headers="thFactorTop" scope="col">{tables.Table1 && tables.Table1.RowHeaders[4].replace("<", "less than ").replace(">", "greater than ")}</th>
                                </tr>
                                {tables.Table2 && tables.Table2.DataRows && tables.Table2.DataRows.map((row) => (
                                    <tr className="text-center">
                                        {row.map((col, index) => (
                                            index == 0 ?
                                                <th
                                                    className="text-left font-weight-normal"
                                                    headers="newPatientArtCyles"
                                                    scope="row"
                                                    dangerouslySetInnerHTML={{ __html: col }} /> :
                                                index != 5 && <td
                                                    headers="newPatientArtCyles"
                                                    className="text-center"
                                                    dangerouslySetInnerHTML={{ __html: (index !== 0 && row[0].indexOf("Average number of") === -1) ? returnDecimalNum(col, false, false, true) : col }} />
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div id="pdfDivTbl3">
                    <b className="App d-block p-2 text-left h5 my-0 font-weight-bold">
                        <div dangerouslySetInnerHTML={{ __html: tables.Table3 && tables.Table3.TableHeader }} />
                    </b>
                    <div className="table-container">
                        <table
                            className="is-striped table is-hoverable is-fullwidth table-bordered table-striped-light th_blue"
                            cellSpacing="1"
                        >
                            <thead>
                                <tr>
                                    <th className="bg-primary border-0"><span className="sr-only">Factor</span></th>
                                    <th className="bg-primary border-0 text-center">{tables.Table3 && tables.Table3.RowHeaders[1]}</th>
                                    <th className="bg-primary border-0 text-center">{tables.Table3 && tables.Table3.RowHeaders[2]}</th>
                                    <th className="bg-primary border-0 text-center">{tables.Table3 && tables.Table3.RowHeaders[3]}</th>
                                    <th className="bg-primary border-0 text-center">{tables.Table3 && tables.Table3.RowHeaders[4]}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tables.Table3 && tables.Table3.DataRows && tables.Table3.DataRows.map((row) => (
                                    <tr className={row[5] ? "text-center bg-secondary-important" : "text-center"}>
                                        {row.map((col, index) => (
                                            <td
                                                key={index}
                                                dangerouslySetInnerHTML={{ __html: index != 0 ? returnDecimalNum(col, false, false, true) : col }} />
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <b className="App d-block p-2 text-left h5 my-0">
                        <div className="font-weight-bold" dangerouslySetInnerHTML={{ __html: tables.Table4 && tables.Table4.TableHeader }} />
                    </b>
                    <div className="table-container">
                        <table
                            className="is-striped table is-hoverable is-fullwidth table-bordered table-striped-light th_blue"
                            cellSpacing="1"
                        >
                            <thead>
                                <tr>
                                    <th id="thFactor" className="bg-primary border-0" colSpan="" aria-hidden="true"></th>
                                    <th id="thPatientAge" className="bg-primary border-0  text-center" colSpan="5" scope="colgroup">Patient Age</th>
                                </tr>
                                <tr aria-hidden="true">
                                    <th className="bg-primary border-0" aria-hidden="true"><span scope="col">Factor</span></th>
                                    <th id="thAgeOne" className="bg-primary border-0 text-center" aria-hidden="true" scope="col">{tables.Table4 && tables.Table4.RowHeaders[1]}</th>
                                    <th id="thAgeTwo" className="bg-primary border-0 text-center" aria-hidden="true" scope="col">{tables.Table4 && tables.Table4.RowHeaders[2]}</th>
                                    <th id="thAgeThree" className="bg-primary border-0 text-center" aria-hidden="true" scope="col">{tables.Table4 && tables.Table4.RowHeaders[3]}</th>
                                    <th id="thAgeFour" className="bg-primary border-0 text-center" aria-hidden="true" scope="col">{tables.Table4 && tables.Table4.RowHeaders[4]}</th>
                                    <th id="thAgeTotal" className="bg-primary border-0 text-center" aria-hidden="true" scope="col">{tables.Table4 && tables.Table4.RowHeaders[5]}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{ height: "0px", display: "block", color: "transparent", border: "none" }}>
                                    <th className="border-0" colSpan="6" >Factor</th>
                                    <th className="sr-only border-0" scope="col">{tables.Table4 && tables.Table4.RowHeaders[1].replace("<", "less than ").replace(">", "greater than ")}</th>
                                    <th className="sr-only border-0" scope="col">{tables.Table4 && tables.Table4.RowHeaders[2].replace("<", "less than ").replace(">", "greater than ")}</th>
                                    <th className="sr-only border-0" scope="col">{tables.Table4 && tables.Table4.RowHeaders[3].replace("<", "less than ").replace(">", "greater than ")}</th>
                                    <th className="sr-only border-0" scope="col">{tables.Table4 && tables.Table4.RowHeaders[4].replace("<", "less than ").replace(">", "greater than ")}</th>
                                    <th className="sr-only border-0" scope="col">{tables.Table4 && tables.Table4.RowHeaders[5].replace("<", "less than ").replace(">", "greater than ")}</th>
                                </tr>
                                {tables.Table4 && tables.Table4.DataRows && tables.Table4.DataRows.map((row, i) => (
                                    <tr className={i === 0 ? "text-center bg-secondary-important" : "text-center"}>
                                        {row.map((col, index) => (
                                            index === 0 ? <th
                                                className="pr-2 text-left font-weight-normal pr-2-modify-dot6rem"
                                                scope="row"
                                                key={index}
                                                dangerouslySetInnerHTML={{ __html: col }} /> :
                                                <td
                                                    className={index == 0 && "pr-2"}
                                                    scope={index == 0 && "row"}
                                                    key={index}
                                                    dangerouslySetInnerHTML={{ __html: index != 0 ? returnDecimalNum(col, false, false, true) : col }} />
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="pdfDivTbl4">
                    <div className="columns row">
                        <div className="column col-6">
                            <div className="d-block p-2 text-left h5 my-0 font-weight-bold" dangerouslySetInnerHTML={{ __html: tables.Table5 && tables.Table5.TableHeader }} />
                            <table
                                cellSpacing="1"
                                className="is-striped table is-hoverable is-fullwidth table-bordered table-striped-light th_blue"
                            >
                                <tbody>
                                    {tables.Table5 && tables.Table5.DataRows && tables.Table5.DataRows.map((row) => (
                                        <tr className="text-center">
                                            {row.map((col, index) => (
                                                <td key={index}>{col}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="column col-6">
                            <b className="d-block p-2 text-left h5 my-0 font-weight-bold">
                                <div dangerouslySetInnerHTML={{ __html: tables.Table6 && tables.Table6.TableHeader }} />
                            </b>
                            <table
                                cellSpacing="1"
                                className="is-striped table is-hoverable is-fullwidth table-bordered table-striped-light th_blue"
                            >
                                <tbody>
                                    {tables.Table6 && tables.Table6.DataRows && tables.Table6.DataRows.map((row) => (
                                        <tr className="text-center">
                                            {row.map((col, index) => (
                                                <td key={index}>{index === 1 ? returnDecimalNum(col, false, false, true) : col}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <p id="legendTextWrapper" dangerouslySetInnerHTML={{ __html: tables.Footer }} />
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
}; // .mapStateToProps

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tab4)
