import React, { Component } from "react";
import * as d3 from "d3";
import { returnDecimalNum } from "../../util";

class BarChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };

        this.ref = React.createRef();
        //alert('vertical construct')
    }

    componentDidMount() {
        this.init();
    }

    init() {
        //alert('init() ')
        const report = this.state.data

        var svg = this.ref.current;

        svg = d3
            .select(svg)
            .attr("version", "1.1")
            .attr(
                "style",
                "font-family: Arial, Helvetica, sans-serif; font-size: 12px;"
            );

        svg
            .append("defs")
            .append("clipPath")
            .append("rect")
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", 583)
            .attr("height", 291);

        svg
            .append("rect")
            .attr("x", 0.5)
            .attr("y", 0.5)
            .attr("width", 666)
            .attr("height", 398)
            .attr("strokeWidth", 1)
            .attr("rx", 4)
            .attr("ry", 4)
            .attr("fill", "#FFFFFF")
            .attr("stroke", "rgba(255, 255, 255, 0)")
            .attr("class", "highcharts-background");

        svg
            .append("rect")
            .attr("x", 75)
            .attr("y", 10)
            .attr("width", 583)
            .attr("height", 291)
            .attr("fill", "#FFFFFF")
            .attr("stroke", "rgba(255, 255, 255, 0)")
            .attr("class", "highcharts-background");

        svg.append("desc").text("Patient Charateristics for Age of patient");
        //svg.append("title").text("Patient Charateristics for Age of patient");

        svg
            .append("g")
            .attr("class", "highcharts-grid")
            .attr("zindex", 1)

            .selectAll("path")
            .data([366.5, 511.5, 658.5, 220.5, 74.5])
            .enter()

            .append("path")
            .attr("fill", "none")
            .attr("d", function (d) {
                return `M ${d} 10 L ${d} 301`;
            })
            .attr("stroke", "#FFFFFF")
            .attr("strokeWidth", 3)
            .attr("zindex", 1)
            .attr("opacity", 1);

        svg
            .append("g")
            .attr("class", "highcharts-grid")
            .attr("zindex", 1)

            .selectAll("path")
            .data([9.5, 68.5, 126.5, 185.5, 243.5, 301.5])
            .enter()

            .append("path")
            .attr("fill", "none")
            .attr("d", function (d) {
                return `M 75 ${d} L 658 ${d}`;
            })
            .attr("stroke", "#C0C0C0")
            .attr("strokeWidth", 1)
            .attr("zindex", 1)
            .attr("opacity", 1)
            .attr("transform","translate(0, 50)");

        var axisX = svg
            .append("g")
            .attr("class", "highcharts-axis")
            .attr("zindex", 2);

        axisX
            .append("text")
            .attr("x", 320)
            .attr("y", 343)
            .attr("zindex", 7)
            .attr("text-anchor", "middle")
            .attr("transform", "translate(0, 50)")
            .attr("class", "highcharts-xaxis-title")
            .attr(
                "style",
                "color: #333333; font-weight: bold; font-size: 14px; font-family: Helvetica, Arial, sans-serif; fill: #333333;"
            )
            .attr("visibility", "visible")

            .append("tspan")
            .text("Age of Patient");

        axisX
            .append("path")
            .attr("fill", "none")
            .attr("d", "M 75 301.5 L 658 301.5")
            .attr("strokeWidth", 1)
            .attr("zindex", 7)
            .attr("visibility", "visible");

        var axisY = svg
            .append("g")
            .attr("class", "highcharts-axis")
            .attr("zindex", 2);

        axisY
            .append("text")
            .attr("x", 26.427734375)
            .attr("y", 155.5)
            .attr("zindex", 7)
            .attr("text-anchor", "middle")
            .attr("transform", "translate(0, 50) rotate(270 26.427734375 155.5)")
            .attr("class", "highcharts-yaxis-title")
            .attr(
                "style",
                "color: #333333; font-weight: bold; font-size: 14px; font-family: Helvetica, Arial, sans-serif; fill: #333333;"
            )
            .attr("visibility", "visible")

            .append("tspan")
            .text("Percent (%)");

        axisY
            .append("path")
            .attr("fill", "none")
            .attr("d", "M 74.5 10 L 74.5 301")
            .attr("strokeWidth", 1)
            .attr("zindex", 7)
            .attr("visibility", "visible");

        var series = svg
            .append("g")
            .attr("class", "highcharts-series-group")
            .attr("zindex", 3);

        // clinic data start
        series
            .append("g")
            .attr("class", "highcharts-series highcharts-tracker")
            .attr("visibility", "visible")
            .attr("zindex", 0.1)
            .attr("transform", "translate(75, 60) scale(1 1)")

            .selectAll("rect")
            .data([
                returnDecimalNum(report.DataRows[0][1].replace("%", "") * 1.0),
                returnDecimalNum(report.DataRows[0][2].replace("%", "") * 1.0),
                returnDecimalNum(report.DataRows[0][3].replace("%", "") * 1.0),
                returnDecimalNum(report.DataRows[0][4].replace("%", "") * 1.0)
            ])
            .enter()

            .append("rect")
            .attr("x", function (d, index) {
                return 38 + 145 * index;
            })
            .attr("y", function (d) {
                return 292 - parseInt((292 * d) / 100);
            })
            .attr("width", 70)
            .attr("height", function (d) {
                return parseInt((292 * d) / 100);
            })
            .attr("fill", "#9167A2")
            .attr("rx", 0)
            .attr("ry", 0)
            .attr("stroke", "#FFFFFF")
            .attr("strokeWidth", 2)
            //tool tip for veritical bar chart data
            .on("mouseover", function (d, i) {
                d3.select(this)
                    .style("fill", "#9167A2")
                    .style("opacity", "0.8");
                d3.select("div.highcharts-tooltip")
                    .style("left", parseInt(d3.select(this).attr("x")) + 170 + "px")
                    .style("top", parseInt(d3.select(this).attr("y")) + 20 + "px");

                d3.select("#tooltipText").html(
                    // `35-37 <br /> Age of patient <br /> ${i}`
                    //`Clinic Data <br /> Age of patient <br /> ${i}`
                    `${report.RowHeaders[i + 1]}<br /> Age of patient <br /> ${Number(d).toFixed(1)}`
                );
            })
            .on("mouseout", function (d, index) {
                d3.select(this)
                    .style("fill", "#9167A2")
                    .style("opacity", "1");
                d3.select("div.highcharts-tooltip")
                    .style("left", "387px")
                    .style("top", "-9999px");
            });
        // clinic data end

        series
            .append("g")
            .attr("class", "highcharts-markers")
            .attr("visibility", "visible")
            .attr("zindex", 0.1)
            .attr("transform", "translate(75, 60) scale(1 1)")
            .attr("clipPath", "none");

        var legend = svg
            .append("g")
            .attr("class", "highcharts-legend")
            .attr("zindex", 7)
            .attr("transform", "translate(301, 416)")

            .append("g")
            .attr("zindex", 1);

        var item = legend
            .append("g")
            .attr("class", "highcharts-legend-item")
            .attr("zindex", 1)
            .attr("transform", "translate(8, 3)");

        item
            .append("text")
            .attr("x", 21)
            .attr("y", 17)
            .attr(
                "style",
                "color: Black; font-size: 14px; font-weight: bold; cursor: pointer; font-family: Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif; fill: Black;"
            )
            .attr("text-anchor", "start")
            .attr("zindex", 2)
            .text("Clinic");
        item
            .append("rect")
            .attr("x", 0)
            .attr("y", 6)
            .attr("width", 16)
            .attr("height", 12)
            .attr("zindex", 3)
            .attr("fill", "#9167A2");

        svg
            .append("g")
            .attr("class", "highcharts-axis-labels highcharts-xaxis-labels one")
            .attr("zindex", 7)

            .selectAll("text")
            .data([
                report.RowHeaders[1],
                report.RowHeaders[2],
                report.RowHeaders[3],
                report.RowHeaders[4]
            ])
            .enter()

            .append("text")
            .attr("x", function (d, index) {
                return 145 + index * 147;
            })
            .attr("y", 323)
            .attr("text-anchor", "middle")
            .attr("transform", "translate(0, 50)")
            .attr(
                "style",
                "color: #333333; cursor: default; font-size: 14px; font-weight: normal; font-family: Helvetica, Arial, sans-serif; fill: #333333;"
            )
            .attr("opacity", 1)
            .text(function (d) {
                return d;
            });
        svg
            .append("g")
            .attr("class", "highcharts-axis-labels highcharts-yaxis-labels")
            .attr("zindex", 7)

            .selectAll("text")
            .data([0, 20, 40, 60, 80, 100])
            .enter()

            .append("text")
            .attr("x", 60)
            .attr("y", function (d, index) {
                return 307 - index * 58.2;
            })
            .attr("text-anchor", "end")
            .attr("transform", "translate(0, 50)")
            .attr(
                "style",
                "width: 200px; color: #333333; cursor: default; font-size: 14px; font-weight: normal; font-family: Helvetica, Arial, sans-serif; fill: #333333;"
            )
            .attr("opacity", 1)
            .text(function (d) {
                return d;
            });

        svg
            .append("g")
            .attr("class", "highcharts-tooltip")
            .attr("zindex", 8)
            .attr("style", "cursor: default; padding: 0; white-space: nowrap;")
            .attr("transform", "translate(96, -9999)")
            .attr("opacity", 0)

            .append("path")
            .attr("fill", "rgba(255, 255, 255, 0)")
            .attr(
                "d",
                "M 3 0 L 101 0 C 104 0 104 0 104 3 L 104 60 C 104 63 104 63 101 63 L 58 63 52 69 46 63 3 63 C 0 63 0 63 0 60 L 0 3 C 0 0 0 0 3 0"
            );
    }
    render() {
        //alert('vertical render()')
        return <svg width="668" height="460" ref={this.ref}></svg>;
    }
}

export default BarChart;
