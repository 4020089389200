import React from "react";
import { Circles } from "react-loader-spinner";

export const Spinner = ({
  message,
  center = false,
  backGround = false,
  cssClass = ""
}) => {
  const spinnerBackdrop = {
    position: "fixed",
    top: "30%",
    left: "0px",
    right: "0px",
    border: "0px",
    background: "#fafafa",
    height: "110px",
    zIndex: "1111",
    margin: "0 auto",
    width: "440px",
    padding: "20px",
    borderRadius: "5px",
    boxShadow: "0px 0px 5px #d4d4d4"
  };
  return (
    <>
      <div style={backGround ? spinnerBackdrop : {}}>
        <div
          className={center && "d-flex justify-content-center"}
          style={{ position: "relative", zIndex: "1" }}
        >
          <div className="form-inline">
            <Circles color="#17a2b8 " height={70} width={70} />
            <span className="ml-4" style={{ fontSize: "20px" }}>
              {message}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Spinner;
