import React, { Component } from "react";
import * as d3 from "d3";
import { returnDecimalNum } from "../../util";

class BarChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };

        this.ref = React.createRef();
    }

    componentDidMount() {
        this.init();
    }

    lineBreak(text, width, x) {
        text.each(function () {
            var el = d3.select(this);
            let words = el.text().split(" ");
            let wordsFormatted = [];

            let string = "";
            for (let i = 0; i < words.length; i++) {
                if (words[i].length + string.length <= width) {
                    string = string + words[i] + " ";
                } else {
                    wordsFormatted.push(string);
                    string = words[i] + " ";

                    el.attr("y", parseInt(el.attr("y")) - 8);
                }
            }
            wordsFormatted.push(string);

            el.text("");
            for (var i = 0; i < wordsFormatted.length; i++) {
                var tspan = el.append("tspan").text(wordsFormatted[i]);
                if (i > 0) tspan.attr("x", x).attr("dy", "20");
            }
        });
    }

    init() {
        const report = this.state.data;

        var svg = this.ref.current;

        svg = d3
            .select(svg)
            .attr("version", "1.1")
            .attr(
                "style",
                "font-family: Arial, Helvetica, sans-serif; font-size: 12px;"
            );

        svg
            .append("defs")
            .append("clipPath")
            .append("rect")
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", 491)
            .attr("height", 510);

        svg
            .append("rect")
            .attr("x", 0.5)
            .attr("y", 0.5)
            .attr("width", 700)
            .attr("height", 598)
            .attr("strokeWidth", 1)
            .attr("rx", 4)
            .attr("ry", 4)
            .attr("fill", "#FFFFFF")
            .attr("stroke", "rgba(255, 255, 255, 0)")
            .attr("class", "highcharts-background");

        svg
            .append("rect")
            .attr("x", 148)
            .attr("y", 10)
            .attr("width", 510)
            .attr("height", 491)
            .attr("fill", "#FFFFFF")
            .attr("stroke", "rgba(255, 255, 255, 0)")
            .attr("class", "highcharts-background");

        svg.append("desc").text("Patient Charateristics");
        //svg.append("title").text("Patient Charateristics for");

        svg
            .append("g")
            .attr("class", "highcharts-grid")
            .attr("zindex", 1)

            .selectAll("path")
            .data([
                10.5, 51.5, 92.5, 133.5, 174.5, 215.5, 256.5, 296.5, 337.5, 378.5,
                419.5, 460.5, 501.5,
            ])
            .enter()

            .append("path")
            .attr("fill", "none")
            .attr("d", function (d) {
                return `M 148 ${d} L 658 ${d}`;
            })
            .attr("stroke", "#FFFFFF")
            .attr("strokeWidth", 3)
            .attr("zindex", 1)
            .attr("opacity", 1);

        svg
            .append("g")
            .attr("class", "highcharts-grid")
             .attr("transform", "translate(77, 0)")
            .attr("zindex", 1)

            .selectAll("path")
            .data([249.5, 351.5, 453.5, 555.5, 658.5, 147.5])
            .enter()

            .append("path")
            .attr("fill", "none")
            .attr("d", function (d) {
                return `M ${d} 10 L ${d} 501`;
            })
            .attr("stroke", "#C0C0C0")
            .attr("strokeWidth", 1)
            .attr("zindex", 1)
            .attr("opacity", 1);

        var axisX = svg
            .append("g")
            .attr("class", "highcharts-axis")
            .attr("transform", "translate(77, 0)")
            .attr("zindex", 2);

        axisX
            .append("text")
            .attr("x", 25)
            .attr("y", 250.5)
            .attr("zindex", 7)
            .attr("text-anchor", "middle")
            .attr("transform", "translate(-85, 0) rotate(270 25.5107421875 255.5)")
            .attr("class", "highcharts-xaxis-title")
            .attr(
                "style",
                "color: #333333; font-weight: bold; font-size: 14px; font-family: Helvetica, Arial, sans-serif; fill: #333333;"
            )
            .attr("visibility", "visible")
            .append("tspan")
            .text("Reason");

        axisX
            .append("path")
            .attr("fill", "none")
            .attr("d", "M 147.5 10 L 147.5 501")
            .attr("stroke", "#C0D0E0")
            .attr("strokeWidth", 1)
            .attr("zindex", 7)
            .attr("visibility", "visible");

        var axisY = svg
            .append("g")
            .attr("class", "highcharts-axis")
            .attr("zindex", 2);

        axisY
            .append("text")
            .attr("x", 403)
            .attr("y", 543)
            .attr("zindex", 7)
            .attr("text-anchor", "middle")
            .attr("transform", "translate(0, 0)")
            .attr("class", "highcharts-yaxis-title")
            .attr(
                "style",
                "color: #333333; font-weight: bold; font-size: 14px; font-family: Helvetica, Arial, sans-serif; fill: #333333;"
            )
            .attr("visibility", "visible")

            .append("tspan")
            .text("Percent (%)");

        axisY
            .append("path")
            .attr("fill", "none")
            .attr("d", "M 148 501.5 L 658 501.5")
            .attr("stroke", "#FFFFFF")
            .attr("strokeWidth", 1)
            .attr("zindex", 7)
            .attr("visibility", "visible");

        var series = svg
            .append("g")
            .attr("class", "highcharts-series-group")
            .attr("zindex", 3);

        // clinic data start
        series
            .append("g")
            .attr("class", "highcharts-series highcharts-tracker")
            .attr("visibility", "visible")
            .attr("zindex", 0.1)
            .attr(
                "transform",
                "translate(735, 501) rotate(90) scale(-1, 1) scale(1 1)"
            )
            .attr("width", 510)
            .attr("height", 491)

            .selectAll("rect")
            .data([
                returnDecimalNum(report.DataRows[0][1].replace("%", "") * 1.0),
                returnDecimalNum(report.DataRows[1][1].replace("%", "") * 1.0),
                returnDecimalNum(report.DataRows[2][1].replace("%", "") * 1.0),
                returnDecimalNum(report.DataRows[3][1].replace("%", "") * 1.0),
                returnDecimalNum(report.DataRows[4][1].replace("%", "") * 1.0),
                returnDecimalNum(report.DataRows[5][1].replace("%", "") * 1.0),
                returnDecimalNum(report.DataRows[6][1].replace("%", "") * 1.0),
                returnDecimalNum(report.DataRows[7][1].replace("%", "") * 1.0),
                returnDecimalNum(report.DataRows[8][1].replace("%", "") * 1.0),
                returnDecimalNum(report.DataRows[9][1].replace("%", "") * 1.0),
                returnDecimalNum(report.DataRows[10][1].replace("%", "") * 1.0),
                returnDecimalNum(report.DataRows[11][1].replace("%", "") * 1.0)
            ])
            .enter()

            .append("rect")
            .attr("x", function (d, index) {
                return 11 + 41 * index;
            })
            .attr("y", function (d) {
                return 511 - parseInt((511 * d) / 100);
            })
            .attr("width", 20)
            .attr("height", function (d) {
                return parseInt((511 * d) / 100);
            })
            .attr("fill", "#9167A2")
            .attr("rx", 0)
            .attr("ry", 0)
            .attr("stroke", "#FFFFFF")
            .attr("strokeWidth", 2)
            .on("mouseover", function (d, i) {
                d3.select(this)
                    .style("fill", "#9167A2")
                    .style("opacity", "0.8");
                d3.select("div.highcharts-tooltip")
                    .style("top", 510 - parseInt(d3.select(this).attr("x")) - 32 + "px")
                    .style("left", parseInt(d3.select(this).attr("height")) + 215 + "px");

                d3.select("#tooltipText").html(
                    // `35-37 <br /> Age of patient <br /> ${i}`
                    // `Clinic Data<br /> Diagnosis <br /> ${i}`
                    //`${report.DataRows[i][0]}<br /> Age of patient <br /> ${d}`
                    `${report.DataRows[i][0]} ${Number(d).toFixed(1)}`
                
                );
            })
            .on("mouseout", function (d, index) {
                d3.select(this)
                    .style("fill", "#9167A2")
                    .style("opacity", "1");
                d3.select("div.highcharts-tooltip")
                    .style("left", "387px")
                    .style("top", "-9999px");
            });
        // clinic data end

        series
            .append("g")
            .attr("class", "highcharts-markers")
            .attr("visibility", "visible")
            .attr("zindex", 0.1)
            .attr(
                "transform",
                "translate(658, 501) rotate(90) scale(-1, 1) scale(1 1)"
            )
            .attr("width", 510)
            .attr("width", 491)
            .attr("clipPath", "none");

        var legend = svg
            .append("g")
            .attr("class", "highcharts-legend")
            .attr("zindex", 7)
            .attr("transform", "translate(301, 566)")

            .append("g")
            .attr("zindex", 1);

        var item = legend
            .append("g")
            .append("g")
            .attr("class", "highcharts-legend-item")
            .attr("zindex", 1)
            .attr("transform", "translate(8, 3)");

        item
            .append("text")
            .attr("x", 21)
            .attr("y", 17)
            .attr(
                "style",
                "color: Black; font-size: 14px; font-weight: bold; cursor: pointer; font-family: Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif; fill: Black;"
            )
            .attr("text-anchor", "start")
            .attr("zindex", 2)
            .text("Clinic");
        item
            .append("rect")
            .attr("x", 0)
            .attr("y", 6)
            .attr("width", 16)
            .attr("height", 12)
            .attr("zindex", 3)
            .attr("fill", "#9167A2");

        svg
            .append("g")
            .attr("class", "highcharts-axis-labels highcharts-xaxis-labels three")
            .attr("zindex", 7)

            .selectAll("text")
            .data([
                report.DataRows[0][0],
                report.DataRows[1][0],
                report.DataRows[2][0],
                report.DataRows[3][0],
                report.DataRows[4][0],
                report.DataRows[5][0],
                report.DataRows[6][0],
                report.DataRows[7][0],
                report.DataRows[8][0],
                report.DataRows[9][0],
                report.DataRows[10][0],
                report.DataRows[11][0]
            ])
            .enter()

            .append("text")
            .attr("x", 210)
            .attr("y", function (d, index) {
                return 482 - index * 40.9;
            })
            .attr("text-anchor", "end")
            .attr("transform", "translate(0, 0)")
            .attr(
                "style",
                "width: 200px; color: #333333; cursor: default; font-size: 14px; font-weight: normal; font-family: Helvetica, Arial, sans-serif; fill: #333333;"
            )
            .attr("opacity", 1)
            .text(function (d) {
                return d;
            })
        //this line will make the text into two line .call(this.lineBreak, 18, 133);

        svg
            .append("g")
            .attr("class", "highcharts-axis-labels highcharts-yaxis-labels")
            .attr("transform", "translate(77, 0)")
            .attr("zindex", 7)

            .selectAll("text")
            .data([0, 20, 40, 60, 80, 100])
            .enter()

            .append("text")
            .attr("x", function (d, index) {
                return 147 + index * 102;
            })
            .attr("y", 523)
            .attr("text-anchor", "middle")
            .attr("transform", "translate(0, 0)")
            .attr(
                "style",
                "width: 200px; color: #333333; cursor: default; font-size: 14px; font-weight: normal; font-family: Helvetica, Arial, sans-serif; fill: #333333;"
            )
            .attr("opacity", 1)
            .text(function (d) {
                return d;
            });

        svg
            .append("g")
            .attr("class", "highcharts-tooltip")
            .attr("zindex", 8)
            .attr("style", "cursor: default; padding: 0; white-space: nowrap;")
            .attr("transform", "translate(175, -9999)")
            .attr("opacity", 0)

            .append("path")
            .attr("fill", "rgba(255, 255, 255, 0)")
            .attr(
                "d",
                "M 3 0 L 97 0 C 100 0 100 0 100 3 L 100 60 C 100 63 100 63 97 63 L 3 63 C 0 63 0 63 0 60 L 0 37 -6 31 0 25 0 3 C 0 0 0 0 3 0"
            );
    }
    render() {
        return <svg width="755" height="600" ref={this.ref}></svg>;
    }
}

export default BarChart;
