import './App.css';
import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
// import {connect} from 'react-redux'
// import {setUserObjAction} from './actions'

import "./styles/style.css";

import { Header, Footer, Title } from 'template-package-react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import "./styles/tp4Override.scss";
import "../node_modules/bootstrap/scss/bootstrap.scss";
import "../node_modules/font-awesome/css/font-awesome.css";
import "./index.scss";
import TabsPage from "./components/TabsPage"
import SamsCallBack from "./components/SamsCallBack"
import TabsPageWithSams from './components/TabsPageWithSams';
import { SkipNav } from './components/SkipNav';
import { LoggedOut } from './components/LoggedOut';


//import {RedirectToSams} from './api'


function App({ user, actions }) {
        // Check if the app is in an iframe
    useEffect(() => {
        if (window.top !== window.self) {
            // Redirect or show an error
            window.top.location = window.self.location; // Redirect to the main site
            alert("Access Denied");
        }else{
            console.log('Loading App...')
        }
    }, []);
    //RedirectToSams();
    // useEffect(() => { 
    //   alert('test1 ', user.email);
    //   if (!user.email)
    //   {
    //     window.location.replace("https://apigw-stg.cdc.gov:8443/auth/oauth/v2/authorize?client_id=bfab691b-91c3-4c1a-8ba4-3a6daf986a90&redirect_uri=http://localhost:3000/samscallback&response_type=code&scope=openid+profile+email");
    //   }
    // });



    //const [userEmail, setUserEmail] = useState(null)

    // useEffect(() => {
    //   //RedirectToSams()

    //   const params = new URLSearchParams(window.location.search);
    //   const SamsCode = params.get("code");
    //   alert(user.email);

    //   if (!SamsCode)
    //   {
    //     //alert("test");
    //     window.location.replace("https://apigw-stg.cdc.gov:8443/auth/oauth/v2/authorize?client_id=bfab691b-91c3-4c1a-8ba4-3a6daf986a90&redirect_uri=http://localhost:3000/samscallback&response_type=code&scope=openid+profile+email");
    //   }
    //   else
    //   {
    //     //actions.setUser({"email": "YRC6@cdc.gov"});
    //     if (!user.email)
    //     {
    //       //alert(SamsCode);
    //       const Data = {
    //         "SamsCode":SamsCode,
    //         "SamsActivity":"NassClinicDashboard",
    //         "SamsEnvironment":"Local"
    //       }
    //       axios({
    //         method: 'post',
    //         //url: 'https://localhost:44302/api/SamsUser',
    //         url: '/SamsAPI/api/SamsUser',
    //         data: Data,
    //         //headers: {"Access-Control-Allow-Origin": "*"}
    //       })
    //       .then(resp => {
    //         console.log(resp.data)
    //         actions.setUser({"email": resp.data.SamsResponse.email});
    //         //alert(resp.data.SamsResponse.email)
    //         // setUserEmail(resp.data.email)
    //       }) // .then
    //       .catch(err => {
    //         console.log('err: ', err)
    //         alert('err: ', err)
    //       }) // .catch
    //     }

    //   }

    // }, [user, actions]);

    return (
        <div>

            {<SkipNav />}
            <Header />
            <div role="region">
                <Title title="NASS Clinic Dashboard" />
            </div>

            {/* <TabsPage /> */}
            <Router basename="/">
                <Routes>
                    <Route exact path="/" element={<TabsPageWithSams />} />
                    <Route exact path="/test" element={<TabsPage />} />
                    <Route exact path="/samscallback" element={<SamsCallBack />} />
                    <Route exact path="/loggedout" element={<LoggedOut />} />
                </Routes>
            </Router>
            <div id="tpFooter" className="d-print-none">            
                <Footer />
            </div>
        </div>

    );
}

//export default App


const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
}; // .mapStateToProps

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App)
