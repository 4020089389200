import { combineReducers } from 'redux';

const initialUserState = {
    email: null,
    clinicid: null,
    year: null
  };

const userReducer = (state = initialUserState, action) => {
    switch (action.type) {
      case 'SET_USER': {
        const { user } = action;
        if (user) {
          //user.Email = user.Email;
          return user;
        }
        return initialUserState;
      }
      case 'CLEAR_REDUX': {
        return initialUserState;
      }
      default:
        return state;
    }
  };


  export default combineReducers({
    user: userReducer,
  });