import React from 'react';

export const SkipNav = () => {
    return (
        <div id="skipmenu">
            <a className="skippy sr-only-focusable" href="#tabContent">
                Skip directly to site content
            </a>
            <a className="skippy sr-only-focusable" href="#tabs-nav">
                Skip directly to page options
            </a>
        </div>
    );
};
