import React, { useEffect } from 'react'
import axios from 'axios'
import {
    useNavigate
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import Cookies from 'universal-cookie';
import { GetEnvironment } from '../api'

function SamsCallBack({ user, actions }) {
    let navigate = useNavigate();

    useEffect(() => {
        //RedirectToSams()

        const params = new URLSearchParams(window.location.search);
        const SamsCode = params.get("code");

        //const cookies = new Cookies();
        //let ClinicId = cookies.get('ClinicId');
        //let Year = cookies.get('Year');
        let ClinicId = sessionStorage.getItem('ClinicId')
        let Year = sessionStorage.getItem('Year')

        //HARD CODING FOR WI FOR WHEN QUERY STRING IS EMPTY, BE SURE DIRECT ACCESS IS ENABLED IN TabsPageWithSams.js
        if (GetEnvironment() == 'dev' && (Year === undefined || Year === 'null' || Year === null || ClinicId === undefined || ClinicId === 'null' || ClinicId === null)) {
            ClinicId = "511";
            Year = "2019";
        }

        if (Year === undefined || Year === 'null' || Year === null || ClinicId === undefined || ClinicId === 'null' || ClinicId === null) {
            //redirect link back to art reporting
            let env = window.location.host,
                artReportingHomePageLink = "";

            switch (true) {
                case env.indexOf('dev') !== -1:
                    artReportingHomePageLink = "https://artreportingdev.cdc.gov/Default.aspx";
                    break;
                case env.indexOf('qa') !== -1:
                    artReportingHomePageLink = "https://artreportingtest.cdc.gov/Default.aspx";
                    break;
                case env.indexOf('int') !== -1:
                    artReportingHomePageLink = "https://artreportingtest.cdc.gov/Default.aspx";
                    break;
                case env.indexOf('prod') !== -1: //is soft prod or regular prod
                    artReportingHomePageLink = "https://artreportingprod.cdc.gov/Default.aspx";
                    break;
                default:
                    artReportingHomePageLink = "https://artreporting.cdc.gov/Default.aspx";
                    break;
                // default code block
            }
            window.location.replace(artReportingHomePageLink);

            console.error('ClinicId and Year are empty or null, or it took more than minutes to authenticate.');
        }
        else {
            //alert(SamsCode)
            if (!SamsCode) {
                //alert('SAMS auth failed');
            }
            else {
                //alert('test sams');

                if (!user.email) {
                    var environment = GetEnvironment();
                    var SamsEnvironment = "";
                    if (environment === "dev") {
                        SamsEnvironment = "Dev";
                    }
                    if (environment === "qa") {
                        SamsEnvironment = "QA";
                    }
                    if (environment === "int") {
                        SamsEnvironment = "Int";
                    }
                    if (environment === "prod") {
                        SamsEnvironment = "Prod";
                    }
                    const Data = {
                        "SamsCode": SamsCode,
                        "SamsActivity": "NassClinicDashboard",
                        "SamsEnvironment": SamsEnvironment
                    }
                    axios({
                        method: 'post',
                        //url: 'https://localhost:44302/api/SamsUser',
                        //url: '/SamsAPI/api/SamsUser',
                        url: '/api/SamsUser',
                        data: Data,
                        //headers: {"Access-Control-Allow-Origin": "*"}
                    })
                        .then(resp => {
                            //console.log(resp.data) loging sam user info
                            actions.setUser(
                                {
                                    "email": resp.data.SamsResponse.email,
                                    "clinicid": ClinicId,
                                    "year": Year
                                }
                            );

                            navigate("/");
                            //alert(resp.data.SamsResponse.email)
                            // setUserEmail(resp.data.email)
                        }) // .then
                        .catch(err => {
                            console.error('err: ', err)
                        }) // .catch
                }
            }

            //alert(user.email);
            //actions.setUser({"email": "YRC6@cdc.gov"});
        }
    }, [user, actions, navigate]);



    return (
        <div>
            <br />
            {/* <span>Redirecting to {this.target}</span> */}
            {/* <span>Redirecting to Google</span> */}
        </div>
    );
}

//export default SamsCallBack;
const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
}; // .mapStateToProps

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SamsCallBack)
