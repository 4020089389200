import React from 'react';
import { doAnalyticsWithTimeout } from '../analytics';

export const LoggedOut = () => {
    document.title = "NASS Clinic Dashboard: logged out"
    doAnalyticsWithTimeout({
        levels: ["Chronic", "DRH", "surveillance", "NASS"],
        AzEntry: null,
        channel: "DRH_NASS_CD"
    });
    return (
        <div className="container">
            <h1>
                Due to 30 minutes of inactivity you have been logged out
            </h1>
            <p>Please <a href="/"> sign in </a> to use the application</p>
        </div>
    );
};
