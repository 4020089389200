import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';
import { Spinner } from "../spinner/Spinner";
import { Modal, Button } from 'react-bootstrap';
import { useState } from 'react';


function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return phoneNumberString;
}

function Tab0({ user, actions, year, tables, isLoading }) {
    const [visibleServiceMeta, setVisibleServiceMeta] = useState(false);
    const btnCloseServiceMeta = () => setVisibleServiceMeta(false);
    const btnShowServiceMeta = () => setVisibleServiceMeta(true);

    const [visibleSummaryMeta, setVisibleSummaryMeta] = useState(false);
    const btnCloseSummaryMeta = () => setVisibleSummaryMeta(false);
    const btnShowSummaryMeta = () => setVisibleSummaryMeta(true);

    if (isLoading) {
        return (
            <div>
                <>
                    <Spinner
                        message="Loading Data..."
                        backGround={true}
                        center={true}
                    />
                </>
            </div>
        )
    }

    return (
        <div id="pdfDiv">
        <div className="tab_in pt-2 px-5">

            <Modal show={visibleServiceMeta} onHide={btnCloseServiceMeta} className="metaDataTableModal" aria-labelledby="metaDataTableModal">
                <Modal.Header>
                    <Modal.Title id="metaDataTableModal">Description of Services and Profile</Modal.Title>
                    <Button onClick={btnCloseServiceMeta} className="bg-transparent border-0 text-dark">
                        X
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <table>
                        <thead>
                            <tr>
                                <th className="h5 py-3">Service</th>
                                <th className="h5 py-3">Description</th>
                            </tr>
                        </thead>
                        <tbody className="table table-bordered bl-0" >
                            {tables.ServicesMetadata && tables.ServicesMetadata.map((row) => (
                                <tr>
                                    {row.map((col, index) => (
                                        <td key={index} className="small">{col}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table></Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={btnCloseServiceMeta}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={visibleSummaryMeta} onHide={btnCloseSummaryMeta} className="metaDataTableModal" aria-labelledby="metaDataTableModalTwo">
                <Modal.Header>
                    <Modal.Title id="metaDataTableModalTwo" >Description of Clinic Summary</Modal.Title>
                    <Button onClick={btnCloseSummaryMeta} className="bg-transparent border-0 text-dark">
                        X
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <table>
                        <thead>
                            <tr>
                                <th className="h5 py-3">Service</th>
                                <th className="h5 py-3">Description</th>
                            </tr></thead>
                        <tbody className="table table-bordered bl-0"  >
                            {tables.ProfilesMetadata && tables.ProfilesMetadata.map((row) => (
                                <tr>
                                    {row.map((col, index) => (
                                        <td key={index} className="small">{col}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table></Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={btnCloseSummaryMeta}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className="columns is-variable bd-klmn-columns is-1 row">
                <div className="column is-one-third col-4">
                    <p className="is-size-4 mb-0 font-weight-bold">
                        {tables.ClinicInfo && tables.ClinicInfo.FacilityName}
                    </p>
                    <p className="is-size-6 mb-0 small-font">{tables.ClinicInfo && tables.ClinicInfo.Address}</p>
                    <p className="is-size-6 mb-0 small-font">{tables.ClinicInfo && tables.ClinicInfo.City}, {tables.ClinicInfo && tables.ClinicInfo.StateDesc} {tables.ClinicInfo && tables.ClinicInfo.ZipCode}</p>
                    <p className="is-size-6 mb-0 small-font">Phone: {tables.ClinicInfo && formatPhoneNumber(tables.ClinicInfo.Phone)}</p>
                    <p>&nbsp;</p>
                    <p className="is-size-5 mb-0 font-weight-bold">Medical Director</p>
                    <p className="is-size-6 mb-0 small-font">{tables.ClinicInfo && tables.ClinicInfo.MedicalDirector}</p>
                </div>
                <div className="column col-4">
                    <table className="table table-bordered is-hoverable is-fullwidth blue_title small-font">
                        <thead>
                            <tr>
                                <th className="bg-primary">
                                    <div className="table_title p-0 border-0 bg-primary">
                                        Clinic Services & Profile
                                        <button
                                            className="button js-modal-trigger question_btn"
                                            data-target="modal_help_1"
                                            aria-haspopup="true"
                                            onClick={btnShowServiceMeta}
                                        >
                                            ?
                                        </button>
                                    </div>
                                </th>
                                <th className="bg-primary"><span className="sr-only">Offered at Clinic</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {tables.Services && tables.Services.map((row) => (
                                <tr>
                                    {row.map((col, index) => (
                                        <td key={index}>{col}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="column col-4 px-0">
                    <table className="table table-bordered is-hoverable is-fullwidth blue_title small-font">
                        <thead>
                            <tr>
                                <th className="bg-primary">
                                    <div className="table_title p-0 border-0 bg-primary">
                                        {year} Clinic Summary
                                        <button
                                            className="button js-modal-trigger question_btn"
                                            data-target="modal_help_2"
                                            aria-haspopup="true"
                                            onClick={btnShowSummaryMeta}
                                        >
                                            ?
                                        </button>
                                    </div>
                                </th>
                                <th className="bg-primary">
                                    <div className="table_title">Number</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {tables.Services && tables.Profile.map((row, rowIndex) => (
                                <tr>
                                    {row.map((col, index) => {
                                        if (rowIndex === 1)
                                            if (index === 0)
                                                return <td key={index} className="pl-4">{col}</td>
                                            else
                                                return <td align="right" key={index}>{col.toLocaleString('en-US')}</td>
                                        else
                                            if (index === 0)
                                                return <td key={index}><b>{col}</b></td>
                                            else
                                                return <td align="right" key={index}><b>{col.toLocaleString('en-US')}</b></td>
                                    }
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            { /*
      <div className="map_block">
        <p className="mb-0">Map</p>
        <iframe
          title="Google map for clinic location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3044.2514615379137!2d-76.66333324843777!3d40.27016397236196!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c8bb5c2bd4597f%3A0xf12ff347c6b7cf69!2zMzUgSG9wZSBEciAjMjAyLCBIZXJzaGV5LCBQQSAxNzAzMywg0KHQv9C-0LvRg9GH0LXQvdGWINCo0YLQsNGC0Lgg0JDQvNC10YDQuNC60Lg!5e0!3m2!1suk!2sua!4v1644268164664!5m2!1suk!2sua"
          width="75%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
*/ }
        </div>
        </div>

    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
}; // .mapStateToProps

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tab0)