//import { useNavigate } from 'react-router-dom';
//import { useSelector } from 'react-redux'


export function RedirectToSams(userObj) {
    //window.location.replace("https://apigw-stg.cdc.gov:8443/auth/oauth/v2/authorize?client_id=bfab691b-91c3-4c1a-8ba4-3a6daf986a90&redirect_uri=http://localhost/SAMSCallback.aspx&response_type=code");
    // const navigate = useNavigate();

    // navigate("https://apigw-stg.cdc.gov:8443/auth/oauth/v2/authorize?client_id=bfab691b-91c3-4c1a-8ba4-3a6daf986a90&redirect_uri=http://localhost/SAMSCallback.aspx&response_type=code");

    return userObj;
}

export function GetEnvironment() {
    var Environment = "prod";

    if (window.location.href.indexOf("localhost") > -1) {
        Environment = "localhost";
    }
    if (window.location.href.indexOf("-dev") > -1) {
        Environment = "dev";
    }
    if (window.location.href.indexOf("-test") > -1) {
        Environment = "qa";
    }
    if (window.location.href.indexOf("-int") > -1) {
        Environment = "int";
    }

    return Environment;
}