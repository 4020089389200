import React from 'react';

export const NoData = ({homeLink}) => {
    return (
        <div className="text-center"id="tabContent">
          <h2>No data found</h2>
            <p>There is no data available for the selected year. Please select a different reporting year and try again.</p>
            <a className="btn btn-primary" href={homeLink} id="tabs-nav">Return to Submission Process</a>
        </div>
    );
};
